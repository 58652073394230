export const PermissionMappingForRole: any = {

//     "GET(Account)": "Account.GetAllAccount",
//     "POST(Account)": "Account.CreateAccount",
//     "PUT(Account)": "Account.UpdateAccount",
//     "DELETE(Account/Id)": "Account.DeleteAccountById",
//     "GET(Account/Id)": "Account.GetAllAccountById",
//     "GET(Account/name)": "Account.GetAllAccountByName",

//     "GET(Brand)": "Brand.GetAllBrand",
//     "POST(Brand)": "Brand.CreateBrand",
//     "PUT(Brand)": "Brand.UpdateBrand",
//     "DELETE(Brand/Id)": "Brand.DeleteBrandById",
//     "GET(Brand/Id)": "Brand.GetBrandById",
//     "GET(Brand/name)": "Brand.GetBrandByName",
//     "GET(Brand/account/Id)": "Brand.GetAllBrandByAccountId",
//     "GET(Brand/accounttype)": "Brand.GetAllBrandByAccountType",

//     "GET(ClientContact)": "ClientContact.GetAllClientContact",
//     "POST(ClientContact)": "ClientContact.CreateClientContact",
//     "POST(ClientContact/Id)": "ClientContact.GetClientContactById",
//     "POST(ClientContact/name)": "ClientContact.GetClientContactByName",
//     "PUT(ClientContact)": "ClientContact.UpdateClientContact",
//     "DELETE(ClientContact/Id)": "ClientContact.DeleteClientContactById",
//     "GET(ClientContact/account/Id)": "ClientContact.GetClientContactByAccountId",

//     "GET(LegalEntity)": "LegalEntity.GetAllLegalEntity",
//     "POST(LegalEntity)": "LegalEntity.CreateLegalEntity",
//     "GET(LegalEntity/account/Id)": "LegalEntity.GetLegalEntityByAccountId",
//     "GET(LegalEntity/Id)": "LegalEntity.GetLegalEntityById",
//     "GET(LegalEntity/name)": "LegalEntity.GetLegalEntityByName",
//     "PUT(LegalEntity)": "LegalEntity.UpdateLegalEntity",
//     "DELETE(LegalEntity/Id)": "LegalEntity.DeleteLegalEntity",

// //     Order.GetAllOrderByBrandId,
//     "GET(Order)": "Order.GetAllOrder",
//     "POST(Order)": "Order.CreateOrder",
//     "PUT(Order)": "Order.UpdateOrder",
//     "DELETE(Order/Id)": "Order.DeleteOrderById",
//     "GET(Order/Id)": "Order.GetAllOrderById",
//     "GET(Order/name)": "Order.GetAllOrderByName",

//     "PUT(Order/Stage)": "Order.UpdateOrderStage",

//     "PUT(OrderTeam)": "OrderTeam.UpdateOrderTeam",
//     "POST(OrderTeam)": "OrderTeam.CreateOrderTeam",
//     "DELETE(OrderTeam/order/Id)": "OrderTeam.DeleteOrderTeamById",

//     "GET(OrderTeam)": "OrderTeam.GetAllOrderTeam",
//     "GET(OrderTeam/Id)": "OrderTeam.GetAllOrderTeamById",
//     "GET(OrderTeam/order/Id)": "OrderTeam.GetAllOrderTeamByOrderId",

//     "PUT(OrderTeamClient)": "OrderTeamClient.UpdateOrderTeamClient",
//     "POST(OrderTeamClient)": "OrderTeamClient.CreateOrderTeamClient",
//     "DELETE(OrderTeamClient/Id)": "OrderTeamClient.DeleteOrderTeamClientById",
//     "GET(OrderTeamClient)": "OrderTeamClient.GetAllOrderTeamClients",
//     "GET(OrderTeamClient/Id)": "OrderTeamClient.GetAllOrderTeamById",
//     "GET(OrderTeamClient/order/Id)": "OrderTeamClient.GetAllOrderTeamClientByOrderId",

//     "PUT(OrderProjection)": "OrderProjection.UpdateOrderProjection",
//     "POST(OrderProjection)": "OrderProjection.CreateOrderProjection",
//     "DELETE(OrderProjection/Id)": "OrderProjection.DeleteOrderProjectionById",
//     "GET(OrderProjection)": "OrderProjection.GetOrderProjectionByOrderId",

//     "GET(Campaign)": "Campaign.GetAllCampaign",
//     "POST(Campaign)": "Campaign.CreateCampaign",
//     "PUT(Campaign)": "Campaign.UpdateCampaign",
//     "DELETE(Campaign/Id)": "Campaign.DeleteCampaignById",
//     "GET(Campaign/Id)": "Campaign.GetAllCampaignById",
//     "GET(Campaign/name)": "Campaign.GetAllCampaignByName",

//     "GET(Placement)": "Placement.GetAllPlacement",
//     "POST(Placement)": "Placement.CreatePlacement",
//     "PUT(Placement)": "Placement.UpdatePlacement",
//     "DELETE(Placement/Id)": "Placement.DeletePlacementById",

//     "GET(PlacementDaytimeParting)": "PlacementDaytimeParting.GetAllPlacementDaytimeParting",
//     "POST(PlacementDaytimeParting)": "PlacementDaytimeParting.CreatePlacementDaytimeParting",
//     "PUT(PlacementDaytimeParting)": "PlacementDaytimeParting.UpdatePlacementDaytimeParting",
//     "DELETE(PlacementDaytimeParting/Id)": "PlacementDaytimeParting.DeleteDaytimePartingById",
//     "GET(PlacementDaytimeParting/Id)": "PlacementDaytimeParting.GetAllPlacementDaytimePartingById",


//     "GET(Placement/Id)": "Placement.GetAllPlacementById",
//     "GET(Placement/name)": "Placement.GetAllPlacementByName",
//     "GET(Placement/Banner/Id)": "Placement.GetAllPlacementByBannerId",
//     "GET(Placement/Campaign/Id)": "Placement.GetAllPlacementByCampaignId",
//     "GET(Placement/DaytimeParting/)": "Placement.UpdatePlacementDaytimeParting",
//     "GET(Placement/DaytimeParting/Id)": "Placement.GetAllDaytimePartingByplacementId",
//     "GET(Placement/FrequencyCap/)": "Placement.UpdatePlacementFrequencyCap",
//     "GET(Placement/FrequencyCap/Id)": "Placement.GetAllFrequencyCapByplacementId",


//     "GET(Banner)": "Banner.GetAllBanners",
//     "POST(Banner)": "Banner.CreateBanner",
//     "PUT(Banner)": "Banner.UpdateBanner",
//     "DELETE(Banner/Id)": "Banner.DeleteBannerById",
//     "GET(Banner/Id)": "Banner.GetBannersById",
//     "GET(Banner/name)": "Banner.GetBannersByName",
//     "GET(Banner/Approved)": "Banner.Approved",
//     "GET(Banner/NotApproved)": "Banner.NotApproved",
//     "GET(Banner/Placement/Id)": "Banner.GetBannersByPlacementId",




//     "GET(AdUnit)": "AdUnit.GetAllAdUnit",
//     "GET(AdUnit/Id)": "AdUnit.GetAllAdUnitById",
//     "GET(AdUnit/name)": "AdUnit.GetAllAdUnitByName",
//     "PUT(AdUnit)": "AdUnit.UpdateAdUnit",
//     "POST(AdUnit)": "AdUnit.CreateAdUnit",

//     "GET(CampaignPricing)": "CampaignPricing.GetAllCampaignPricing",
//     "POST(CampaignPricing)": "CampaignPricing.CreateCampaignPricing",
//     "PUT(CampaignPricing)": "CampaignPricing.UpdateCampaignPricing",
//     "GET(CampaignPricing/Id)": "CampaignPricing.GetAllCampaignPricingById",


//     "GET(CampaignSchedule/campaign/Id)": "CampaignSchedule.GetCampaignScheduleByCampaignId",
//     "POST(CampaignSchedule)": "CampaignSchedule.CreateCampaignSchedule",
//     "PUT(CampaignSchedule)": "CampaignSchedule.UpdateCampaignSchedule",
//     "DELETE(CampaignSchedule/Id)": "CampaignSchedule.DeleteCampaignScheduleById",


//     "GET(Campaign/frequencyCap/Id)": "Campaign.GetAllFrequencyCapByCampaignId",
//     "PUT(Campaign/FrequencyCap)": "Campaign.UpdateCampaignFrequencyCap",
//     "GET(Campaign/totalFoc/Id)": "Campaign.GetAllTotalFocByCampaignId",
//     "PUT(Campaign/TotalFoc)": "Campaign.UpdateCampaignTotalFoc",

//     "GET(TargetingGroup)": "TargetingGroup.GetAllTargetingGroups",
//     "POST(TargetingGroup)": "TargetingGroup.CreateTargetingGroup",
//     "PUT(TargetingGroup)": "TargetingGroup.UpdateTargetingGroup",
//     "DELETE(TargetingGroup/Id)": "TargetingGroup.DeleteTargetingGroup",

//     "GET(Role)": "Role.GetAllRole",
//     "POST(Role)": "Role.CreateRole",
//     "PUT(Role)": "Role.UpdateRole",
//     "DELETE(Role/Id)": "Role.DeleteRoleById",

//     "GET(Policy)": "Policy.GetAllPolicies",
//     "POST(Policy)": "Policy.CreatePolicy",
//     "PUT(Policy)": "Policy.UpdatePolicy",
//     "DELETE(Policy/Id)": "Policy.DeletePolicyById",

//     "POST(Role/)": "Role.AddUser",
//     "DELETE(Role)": "Role.DeleteUser",

//     "GET(user)": "User.GetAllUsers",
//     "POST(user)": "User.CreateUser",
//     "PUT(user)": "User.UpdateUser",

//     "GET(CustomAudience)": "Custom.GetCustomAudience",
//     "POST(CreateCustomAudience)": "Custom.CreateCustomAudience",
//     "PUT(UpdateCustomAudience)": "Custom.UpdateCustomAudience",
//     "GET(CustomAudience/Id)": "Custom.GetCustomAudienceById",
//     "DELETE(CustomAudience/Id)": "Custom.DeleteCustomAudienceById",
//                    //     Custom.DeleteCustomAudience,

//     "GET(Tenant)": "Tenant.GetAllTenant",
//     "POST(Tenant)": "Tenant.CreateTenant",
//     "PUT(Tenant)": "Tenant.UpdateTenant",
//     "DELETE(Tenant/Id)": "Tenant.DeleteTenantById",
//     "GET(Tenant/Id)": "Tenant.GetAllTenantById",
//     "GET(Tenant/name)": "Tenant.GetAllTenantByName",


}
  /////////////////////////////////////////////////////////////////
// AdUnit.DeleteAdUnitById,
//     Auth.ForgotPassword,

//     Banner.AddPlacement,
//     Banner.DeletePlacement,
//     Banner.GetActiveBannersByAccountId,
//     Banner.GetActiveBannersByChannelId,
//     Banner.GetApprovedBannersByChannelId,
//     Banner.GetBannerByChannel,
//     Banner.Rejected,

//     CampaignPricing.GetAllCampaignPricingByCampaignId,


//     Campaign.UpdateCampaignDaytimeParting,
//     Campaign.GetAllDaytimePartingByCampaignId,

//     Campaign.UpdateCampaignFrequencyCap,
//     Campaign.GetAllFrequencyCapByCampaignId,

//     Campaign.UpdateCampaignTotalFoc,
//     Campaign.GetAllTotalFocByCampaignId,


//     Code.GetAllCodes,
//     Code.GetCodesByCodeTypeId,
//     Code.GetCodesByCodeTypeName,
//     Code.CreateCode,
//     Code.UpdateCode,
//     Code.DeleteCodeById,

//     CodeType.GetAllCodeTypes,
//     CodeType.CreateCodeType,
//     CodeType.UpdateCodeType,
//     CodeType.DeleteCodeTypeById,

//     OrderProjection.CreateOrderProjection,
//     OrderProjection.UpdateOrderProjection,
//     OrderProjection.DeleteOrderProjectionById,
//     OrderProjection.GetOrderProjectionByOrderId,
//     OrderTeamClient.GetAllOrderTeamClients,
//     OrderTeamClient.GetAllOrderTeamById,
//     OrderTeamClient.CreateOrderTeamClient,
//     OrderTeamClient.UpdateOrderTeamClient,
//     OrderTeamClient.DeleteOrderTeamClientById,
//     OrderTeamClient.GetAllOrderTeamClientByOrderId,
//     OrderTeam.GetAllOrderTeam,
//     OrderTeam.GetAllOrderTeamById,
//     OrderTeam.CreateOrderTeam,
//     OrderTeam.UpdateOrderTeam,
//     OrderTeam.DeleteOrderTeamById,
//     OrderTeam.GetAllOrderTeamByOrderId,



//     Placement.GetAllPlacement,
//     Placement.GetAllPlacementById,
//     Placement.CreatePlacement,
//     Placement.UpdatePlacement,
//     Placement.DeletePlacementById,
//     Placement.GetAllPlacementByCampaignId,
//     Placement.GetAllPlacementByName,
//     Placement.GetAllPlacementByBannerId,
//     Placement.UpdatePlacementDaytimeParting,
//     Placement.GetAllDaytimePartingByplacementId,
//     Placement.UpdatePlacementFrequencyCap,
//     Placement.GetAllFrequencyCapByplacementId,
//     PlacementGroup.GetAllPlacementGroup,
//     PlacementGroup.GetAllPlacementGroupById,
//     PlacementGroup.GetAllPlacementGroupByName,
//     PlacementGroup.CreatePlacementGroup,
//     PlacementGroup.UpdatePlacementGroup,
//     PlacementGroup.DeletePlacementGroupById,
//     PlacementDaytimeParting.GetAllPlacementDaytimePartingById,
//     PlacementDaytimeParting.CreatePlacementDaytimeParting,
//     PlacementDaytimeParting.UpdatePlacementDaytimeParting,
//     PlacementDaytimeParting.DeleteDaytimePartingById,
//     PlacementTargeting.CreatePlacementTargeting,
//     PlacementTargeting.UpdatePlacementTargeting,
//     PlacementTargeting.GetAllPlacementTargetingById,
//     PlacementTargeting.GetAllPlacementTargeting,


//     Role.AddUser,
//     Role.DeleteUser,
//     Role.AddPolicy,
//     Role.DeletePolicy,
//     TargetingGroup.CreateTargetingGroup,
//     TargetingGroup.UpdateTargetingGroup,
//     TargetingGroup.DeleteTargetingGroup,
//     TargetingGroup.GetAllTargetingGroups,
//     TargetingGroup.GetTargetingGroupByPlacementId,
//     User.CreateUser,
//     User.UpdateUser,
//     User.GetAllUsers,
//     Approvals.CreateRequest,
//     Approvals.UpdateRequest,
//     Approvals.GetAllApproval,
//     Approvals.GetAllApprovalById,


//     UserTenantMapping.CreateUserTenant,
//     UserTenantMapping.UpdateUserTenant,
//     UserTenantMapping.DeleteUserTenantById,
//     UserTenantMapping.GetAllUserTenant,
//     UserTenantMapping.GetAllUserTenantById,

//     Segment.GetAllSegment,
//     Segment.GetAllSegmentById,
//     Segment.GetAllSegmentByName,
//     Segment.CreateSegment,
//     Segment.UpdateSegment,
//     Segment.DeleteSegmentById