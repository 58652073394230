import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { filterOnTable } from 'src/app/utilities/tablet-filter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-active-campaign-report',
  templateUrl: './active-campaign-report.component.html',
  styleUrls: ['./active-campaign-report.component.scss']
})
export class ActiveCampaignReportComponent implements OnInit {
  getActiveCampaignReports_main: any
  getActiveCampaignReports: any
  dataSourceClone: any
  displayColumns: any
  bannerUrl = environment.bannerUrl;
  constructor(private campaignService: CampaignsService) {

  }
  ngOnInit(): void {
    this.displayColumns = [
      "Account Id",
      "Account Name",
      "Ad Format",
      "Banner Id",
      "Banner Name",
      "Billing Metric Name",
      "Brand Name",
      "Business Model Name",
      "Campaign Id",
      "Campaign Name",
      "Ecpm Value",
      "Order Name",
      "Order Type Name",
      "Placement Id",
      "Placement Name",
      "Pricing Scheme",
      "Priority Name",
      "Property Name",
      "Rate",
      "Preview"
    ]


    this.campaignService.getActiveCampaignReport().subscribe((res: any) => {
      const mappedDataSource: any = [];

      const arr = Object.keys(res).map(key => res[key]);
      this.getActiveCampaignReports = res
         arr.forEach((ele: any) => {
        mappedDataSource.push({
          "Account Id": ele.accountId,
          "Account Name": ele.accountName,
          "Ad Format": ele.adFormat,
          "Banner Id": ele.bannerId,
          "Banner Name": ele.bannerName,
          "Billing Metric Name": ele.billingMetricName,
          "Brand Name": ele.brandName,
          "Business Model Name": ele.businessModelName,
          "Campaign Id": ele.campaignId,
          "Campaign Name": ele.campaignName,
          "Ecpm Value": ele.ecpmValue,
          "Order Name": ele.orderName,
          "Order Type Name": ele.orderTypeName,
          "Placement Id": ele.placementId,
          "Placement Name": ele.placementName,
          "Pricing Scheme": ele.pricingScheme,
          "Priority Name": ele.priorityName,
          "Property Name": ele.propertyName,
          "Rate": ele.rate,
          "Preview": `${this.bannerUrl}${ele.accountId}/${JSON.parse(ele.bannerCreatives)[0].ImageURL[0].ImageURL}`

        })
      });
      this.dataSourceClone = JSON.parse(JSON.stringify(mappedDataSource));
      this.getActiveCampaignReports = new MatTableDataSource(mappedDataSource);
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.getActiveCampaignReports.data = filterOnTable(this.dataSourceClone, filterValue);

  }
}
