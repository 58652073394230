import { Injectable } from '@angular/core';
import { ApplicationApiService } from './application-api.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  orderId: any;
  constructor(private applicationApiService:ApplicationApiService) { }

  // getter setter
  public _orderFormData: any;
  public _orderTeamData: any;

  public _orderProjectionFormData: any;
  public notifyOnSaveCreateCampaign: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  get orderFormData(){
    return this._orderFormData;
   }
   set orderFormData(data){
    this._orderFormData = data;
   }

   get orderTeamData(){
    return this._orderTeamData;
   }
   set orderTeamData(data){
    this._orderTeamData = data;
   }

  getOrderList(){
    const queryParams = {
      "pageNumber":1,
      "rows":1000,
      "sortingColumn":"None",
      "sortType":"DESC"
    }
    return this.applicationApiService.getData("Order",queryParams);
  }

  getOrderTypeDropDownList(){
    const queryParamsDropdown = {
      "pageNumber": 1,
      "rows": 1000,
      "sortingColumn": "None",
      "sortType": "ASC"
    }
    return this.applicationApiService.getData("code/codetypename/Order%20type?", queryParamsDropdown)
  }
  getOrderTypeStageList(){
    const queryParamsDropdown = {
      "pageNumber": 1,
      "rows": 1000,
      "sortingColumn": "None",
      "sortType": "ASC"
    }
    return this.applicationApiService.getData("code/codetypename/Order%20stage?", queryParamsDropdown)
  }

  getOrderById(id: string) {
    return this.applicationApiService.getData(`Order/${id}`, "");
  } 
  saveOrderList(body:any){
    return this.applicationApiService.postData("Order",body);
  }
  updateOrderList(body:any){
    return this.applicationApiService.putData("Order",body);
  }
  deleteOrderList(id:number){
    return this.applicationApiService.deleteData("Order/" + id);
  }

  updateOrderStages(stage:number){
    const queryParamsDropdown = {
      "orderId": this.orderFormData.orderId,
      "stage":stage
    }
    return this.applicationApiService.putDataWithParams("Order/Stage",queryParamsDropdown);
  }

  

   getOrderTeamId(id: string) {
    return this.applicationApiService.getData(`OrderTeam/${id}`, "");
  } 
   getOrderTeamByOrderId(){
    return this.applicationApiService.getData("OrderTeam/order/" + this._orderFormData?.Id, "");
   }

   getOrderTeams(){
    const queryParamsDropdown = {
      "pageNumber": 1,
      "rows": 1000,
      "sortingColumn": "None",
      "sortType": "ASC"
    }
    return this.applicationApiService.getData("user", queryParamsDropdown);
   }
   saveOrderTeamsList(body: any){
    return this.applicationApiService.postData("OrderTeam",body);
  }
  updateOrderTeamsList(body:any){
    return this.applicationApiService.putData("OrderTeam",body);
  }
  saveOrderClientList(body: any) {
    return this.applicationApiService.postData("OrderTeamClient",body);
  }
  updateOrderClientList(body: any) {
    return this.applicationApiService.putData("OrderTeamClient",body);
  }
  deleteOrderAddMemberList(id:number){
    return this.applicationApiService.deleteData("OrderTeam/" + id);
  }

  getClientTeamsByIdList(){
    return this.applicationApiService.getData("OrderTeamClient/order/" + this._orderFormData?.Id, "");
   }
  getClientTeamsForMultiDropdownAccounts(id: number){
    return this.applicationApiService.getData("ClientContact/account/" + id,"");
  } 
  // getClientTeamsForMultiDropdownAdvertiser(){
  //   return this.applicationApiService.getData("Brand/accounttype/Advertiser" ,"");
  // } 
 
 
  saveClientTeamsList(){
    const body = {
      "userId": Number(sessionStorage.getItem('userId')),
      "orderId": Number(this._orderFormData.Id)  
    }
    return this.applicationApiService.postData("OrderTeamClient",body);
  }
  deleteOrderTeam(id:number){
    return this.applicationApiService.deleteData("OrderTeam/" + id)
  }
  deleteOrderClient(id:number){
    return this.applicationApiService.deleteData("OrderTeamClient/" + id)
  }
  deleteOrderProjection(id:number){
    return this.applicationApiService.deleteData("OrderProjection/" + id)
  }
  get orderProjectionFormData(){
    return this._orderProjectionFormData;
   }
   set orderProjectionFormData(data){
    this._orderProjectionFormData = data;
   }

  getOrderProjectionList(){
    return this.applicationApiService.getData("OrderProjection/Order/" + this._orderFormData?.Id,"");
  }
  saveOrderProjectionList(body:any){
    return this.applicationApiService.postData("OrderProjection",body);
  }
  updateOrderProjectionList(body:any){
    return this.applicationApiService.putData("OrderProjection",body);
  }
  
}
