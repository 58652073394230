import { Injectable, NgZone } from '@angular/core';
import { ApplicationApiService } from './application-api.service';
import { Router } from '@angular/router';
import { Observable, Subscription, debounceTime, interval } from 'rxjs';
import { DashboardControlService } from './dashboard-control.service';
import { SessionTimeoutComponent } from '../shared/components/session-timeout/session-timeout.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private activitySubscription: Subscription;
  private idleTimeout = 20 * 60 * 1000;
  private timeoutWarning = 1 * 60 * 1000;
  private timeoutId: any;
  private _roleFormData: null;

  constructor(
    private applicationApiService: ApplicationApiService,
    private router: Router,
    private dashboardControlService: DashboardControlService,
    private ngZone: NgZone,
    private dialog: MatDialog
  ) { }
  public _changePasswordData: any;

  loginApplication(body: any): Observable<any> {
    return this.applicationApiService.postData("Auth/authenticate", body);
  }

  setStartTimer() {
    this.startTimer();
    this.resetTimer();
    setInterval(() => {
      const currentTime: any = new Date().toUTCString();
      const tokenExpiryTime: any = (sessionStorage.getItem('tokenExpiresAt') || "")
      const diffInSec = (new Date(tokenExpiryTime).getTime() - new Date(currentTime).getTime()) / 1000;
      if (diffInSec <= 60) { // Less than or equal to 60 seconds left before token expiry
        const refreshToken = sessionStorage.getItem('refreshToken');
        if (refreshToken) {
          const body = { "refreshToken": refreshToken };
          this.refreshToken(body).subscribe((response: any) => {
            if (response && response.token && response.expires && response.refreshToken && response.refreshTokenExpires) {
              sessionStorage.setItem("token", response.token);
              const refreshTokenExpiry = new Date(response.refreshTokenExpires).toUTCString()
              sessionStorage.setItem("refreshTokenExpires", refreshTokenExpiry);
              const tokenExpiry = new Date(response.expires).toUTCString()
              sessionStorage.setItem("tokenExpiresAt", tokenExpiry);
              sessionStorage.setItem("refreshToken", response.refreshToken);
            } else {
              this.logoutUser();
            }
          }, (error: any) => {
            this.logoutUser();
          });
        } else {
          this.logoutUser();
        }
      } else if (diffInSec < 0) { // Token has expired
        this.logoutUser();
      }
    }, 60000); // Check every 60 seconds
  }

  private startTimer() {
    this.ngZone.runOutsideAngular(() => {
      this.timeoutId = setTimeout(() => {
        this.ngZone.run(() => this.showTimeoutDialog());
      }, this.idleTimeout - this.timeoutWarning);
    });
  }
  private restartTimer() {
    clearTimeout(this.timeoutId);
    this.startTimer();
  }
  private resetTimer() {
    document.body.addEventListener('mousemove', () => this.restartTimer());
    document.body.addEventListener('keypress', () => this.restartTimer());
  }

  private showTimeoutDialog() {
    const dialogRef = this.dialog.open(SessionTimeoutComponent, {
      width: '400px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'continue') {
        this.restartTimer();
      } else {
        this.logoutUser();
      }
    });
  }

  private refreshToken(body: any): Observable<any> {
    this.dashboardControlService.showPlacementSaveInAdunit = true;
    return this.applicationApiService.postData("Auth/refresh-token", body);
  }

  private logoutUser() {
    sessionStorage.clear(); // Clear session storage
    this.router.navigate(['/login']); // Redirect to login page
    if (this.activitySubscription) {
      this.activitySubscription.unsubscribe(); // Unsubscribe from activity check
    }
  }

  changePassword(body: any) {
    return this.applicationApiService.postData("Auth/update-password", body);
  }

  forgotPassword(body: any) {
    return this.applicationApiService.postData("Auth/forgot-password", body);
  }
}
