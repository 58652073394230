import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignsService } from 'src/app/services/campaigns.service';
import { filterOnTable } from 'src/app/utilities/tablet-filter';

@Component({
  selector: 'app-campaign-data',
  templateUrl: './campaign-data.component.html',
  styleUrls: ['./campaign-data.component.scss']
})
export class CampaignDataComponent implements OnInit {
  dateFrom: string;
  dateTo: string;
  includeBannerData: boolean;
  campaignId: number;
  displayedColumns: string[] = [
    "Date",
    "Order ID",
    "Order Name",
    "Campaign ID",
    "Campaign Name",
    "Banner ID",
    "Banner Name",
    "Schedule ID",
    "Daily Requirement",
    "Scheme",
    "Sum Of Impressions",
    "Sum Of Clicks",
    "CTR",
    "Campaign Is Active"
  ]
  campaignDta:any
  dataSourceClone: any
  constructor(private route: ActivatedRoute, private campaignService: CampaignsService,private router: Router) {

   }
  // ngOnInit() {
  //   this.route.queryParams.subscribe(params => {
  //     this.dateFrom = params['dateFrom'] || 'default-date-from';
  //     this.dateTo = params['dateTo'] || 'default-date-to';
  //     this.includeBannerData = params['includeBannerData'] === 'true';
  //     this.campaignId = +params['campaignId'] || 0;


      // this.campaignService.getCampaignData(this.dateFrom,this.dateTo,this.includeBannerData,this.campaignId).subscribe((res:any)=>{
      //   const mappedDataSource: any = [];

      //   const arr = Object.keys(res).map(key => res[key]);
      //   this.campaignDta=res
      //   arr.forEach((ele: any) => {
      //     mappedDataSource.push({
      //       "Date": ele.date,
      //       "Order ID": ele.orderID,
      //       "Order Name": ele.orderName,
           
      //       "Campaign ID": ele.campaignID,
      //       "Campaign Name": ele.campaignName,
      //       "Banner ID": ele.bannerID,
      //       "Banner Name": ele.bannerName,
      //       "Schedule ID": ele.scheduleID,
           
      //       "Daily Requirement": ele.dailyRequirement,
      //       "Scheme": ele.scheme,
      //       "Sum Of Impressions": ele.sumOfImpressions,
      //       "Sum Of Clicks": ele.sumOfClicks,
      //       "CTR": ele.ctr,
      //       "Campaign Is Active": ele.campaignIsActive,
           

      //     })
      //     this.dataSourceClone = JSON.parse(JSON.stringify(mappedDataSource));
      //     this.campaignDta = new MatTableDataSource(mappedDataSource);
          

      //   });
      // })
     
  //    // this.updateComponentBasedOnParams();
  //   });
  // }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      // Check if parameters are already in the URL
      const hasAllParams = params['dateFrom'] && params['dateTo'] && params['includeBannerData'] !== undefined && params['campaignId'];

      if (!hasAllParams) {
        // If any parameter is missing, navigate with default parameters
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            dateFrom: params['dateFrom'] || 'null',
            dateTo: params['dateTo'] || 'null',
            includeBannerData: params['includeBannerData'] !== undefined ? params['includeBannerData'] : false,
            campaignId: params['campaignId'] || 0
          },
          queryParamsHandling: 'merge' // preserve other query params if any
        });
      } else {
        // If all parameters are present, use them
        this.dateFrom = params['dateFrom'];
        this.dateTo = params['dateTo'];
        this.includeBannerData = params['includeBannerData'] === 'true';
        this.campaignId = +params['campaignId'];

        //this.updateComponentBasedOnParams();
      }
      this.campaignService.getCampaignData(this.dateFrom, this.dateTo, this.includeBannerData, this.campaignId).subscribe((res: any) => {
        const mappedDataSource: any = [];

        const arr = Object.keys(res).map(key => res[key]);
        this.campaignDta = res
        arr.forEach((ele: any) => {
          mappedDataSource.push({
            "Date": ele.date,
            "Order ID": ele.orderID,
            "Order Name": ele.orderName,

            "Campaign ID": ele.campaignID,
            "Campaign Name": ele.campaignName,
            "Banner ID": ele.bannerID,
            "Banner Name": ele.bannerName,
            "Schedule ID": ele.scheduleID,

            "Daily Requirement": ele.dailyRequirement,
            "Scheme": ele.scheme,
            "Sum Of Impressions": ele.sumOfImpressions,
            "Sum Of Clicks": ele.sumOfClicks,
            "CTR": ele.ctr,
            "Campaign Is Active": ele.campaignIsActive,


          })
          this.dataSourceClone = JSON.parse(JSON.stringify(mappedDataSource));
          this.campaignDta = new MatTableDataSource(mappedDataSource);


        });
      })
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.campaignDta.data = filterOnTable(this.dataSourceClone, filterValue);

  }
}
