import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { isRouteWithoutPermission, routeGuardMapping } from '../constants/route-guard-mapper';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../shared/components/error-dialog/error-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, private dialog: MatDialog, private toastr: ToastrService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = next.data['activateUrl'];
    const permission = sessionStorage.getItem('permission');

    if (permission) {
      if(this.isAuthorized( url || state.url)){
        return true;
      }else{
        this.dialog.open(ErrorDialogComponent, {
          data: {
            title: '403 Forbidden Error',
            errorMessage: 'You do not have permission to access this resource.'
          }
        });
        return false;
      }
    } else {
      this.router.navigate(['/login']);
      return false;
    }
  }

  isAuthorized(url: string): boolean {
    const permission = sessionStorage.getItem('permission');
    if (routeGuardMapping[url]) {
      if (permission) {
        if (permission?.split(',').includes(routeGuardMapping[url])) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    } else if (isRouteWithoutPermission(url)) {
      return true; // always allow this routes to proceed
    }
    return false;
  }


  isFeatureBlocked(featureName: string) {
    const permission = sessionStorage.getItem('permission');
    if (!(permission?.split(',')?.includes(featureName))) {
      this.toastr.error('Please get required permission to access this feature.', 'Error', {
        timeOut: 3000,
      });
      return true;
    } else {
      return false;
    }
  }
}
