import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, finalize, map, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { RoutesMenu } from './utilities/menu';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from './shared/components/error-dialog/error-dialog.component';
import { DashboardControlService } from './services/dashboard-control.service';
import { PermissionMappingForRole } from './utilities/authorization';

@Injectable()
export class HttpInterInterceptor implements HttpInterceptor {

  
  isDialogOpen: boolean = false;

  constructor(private router: Router, private dialog: MatDialog, public dashboardControlService: DashboardControlService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const leftSide = `${request.method}(${request.url.split('.net/')?.[1]})`;
    const rightSide = PermissionMappingForRole[leftSide];
    const matchRole = sessionStorage.getItem('permission')?.split(',')?.find(role => role === rightSide);
    if (!matchRole && rightSide) {
      this.dialogHandler({
        statusText: "Error",
        error: {
          ErrorMessage: "You are not eligible to access this feature. Please contact system administrator.",
          status: "Unauthorized access!"
        }
      });
      return throwError(() => "Error");
    }
    const authReq = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${sessionStorage.getItem("token")}`)
    });
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if(!error?.message?.includes("Auth/OTPVerification")){
          this.dialogHandler({
            statusText: "Error",
            error: {
              ErrorMessage: error?.error?.ErrorMessage,
              status: error?.status
            }
          });
        }
        return throwError(() => error);
      }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status >= 200 && event.status < 300) {
          this.handleSuccessResponse(event, request);
        }
        return event;
      })
    );
  }

  handleSuccessResponse(event: HttpResponse<any>, request: HttpRequest<any>): void {
    if (!request.url?.includes("Auth/authenticate") && (!request.url?.includes('AdRequest/Post'))) { 
      if (this.dashboardControlService.showPlacementSaveInAdunit && request.method === 'POST') {
        this.dashboardControlService.showPlacementSaveInAdunit = false;
      } else if (request.method === 'POST' && 
        !request?.url?.includes('Auth/OTPVerification') &&
        !request?.url?.includes('Auth/ResendOTP') &&
        !request?.url?.includes('authenticate')
      ) {
        this.dialogHandler({
          statusText: "Success",
          error: {
            ErrorMessage: "Data saved successfully"
          }
        });
      } else if (this.dashboardControlService.isDelete || request.method === 'DELETE') {
        this.dialogHandler({
          statusText: "Deletion Successful",
          error: {
            ErrorMessage: "Data is deleted"
          }
        });
        this.dashboardControlService.isDelete = false;
      } else if (this.dashboardControlService.isNewEntry && request.method === 'PUT') {
        this.dialogHandler({
          statusText: "Success",
          error: {
            ErrorMessage: "Data saved successfully"
          }
        });
        this.dashboardControlService.isNewEntry = false;
      } else if (this.dashboardControlService.isStageSave && request.method === 'PUT') {
        this.dashboardControlService.isStageSave = false;
      } else if (request.method === 'PUT') {
        this.dialogHandler({
          statusText: "Success",
          error: {
            ErrorMessage: "Data updated successfully"
          }
        });
      }
    }
  }

  dialogHandler(error: any) {
    let redirectUrl=localStorage.getItem('newTabUrl');
    if(redirectUrl && error.statusText!="Success"){
      return
    }
    if (!this.isDialogOpen && error?.error?.status!=200) {
      this.isDialogOpen = true;
      let dialog;
      if (error?.error?.status === 401) {
        dialog = this.dialog.open(ErrorDialogComponent, {
          data: {
            title: 'Session Logout',
            errorMessage: 'Your session has expired. Please log in again.'
          }
        });
        this.router.navigate([RoutesMenu.login]);
      } 
      
      else if(error?.error?.status === 403){
        dialog = this.dialog.open(ErrorDialogComponent, {
          data: {
            title: '403 Forbidden Error',
            errorMessage: 'You do not have permission to access this resource.'
          }
        });
      }
      
      else {
        dialog = this.dialog.open(ErrorDialogComponent, {
          data: {
            title: error?.statusText || 'Error',
            errorMessage: error?.error?.ErrorMessage || "An error occurred"
          }
        });
      }

      dialog.afterClosed().subscribe((isRetry: any) => {
        this.isDialogOpen = false;
        if (isRetry) {
          // Handle retry logic if needed
        } else if (error?.status === 401) {
          this.router.navigate([RoutesMenu.login]);
        }
      });
    }
  }



}






