<!-- <div fxLayout="row" fxLayoutAlign="space-between">
    <div class="back-button">
        <button (click)="goToUserBox()" mat-raised-button class="blue-color mt-1"><mat-icon
                class="mb-1">navigate_before</mat-icon>Back</button>
    </div>
    <div class="adunit-button" *ngIf="showEditOption">
        <button mat-raised-button color="primary" (click)="OnEditEnable()" class="me-2 mt-2">Edit</button>
        <button (click)="delete()" mat-raised-button color="accent" class="me-2 mt-2">Delete</button>

    </div>
</div> -->

<div class="create-role-container">
    <form [formGroup]="changePasswordForm">
        <mat-card>
            <div class="create-role">
                <div>
                    <h2>Change Password</h2>
                </div>
                <div class="role-container">
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
                        <div class="mt-3 red-star">Current Password</div>
                        <div fxLayout="column" class="w-70">
                            <mat-form-field>
                                <input matInput [type]="currentPasswordVisible ? 'text' : 'password'"
                                    placeholder="Enter Current Password" formControlName="currentPassword">
                                <button mat-icon-button matSuffix (click)="toggleCurrentPasswordVisibility()">
                                    <mat-icon>{{ currentPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
                                </button>
                                <mat-error
                                    *ngIf="changePasswordForm.get('currentPassword')?.hasError('required') && changePasswordForm.get('currentPassword')?.touched">
                                    Current Password is required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
                        <div class="mt-3 red-star">New Password</div>
                        <div fxLayout="column" class="w-70">
                            <mat-form-field>
                                <input matInput [type]="newPasswordVisible ? 'text' : 'password'"
                                    placeholder="Enter New Password" formControlName="newPassword">
                                <button mat-icon-button matSuffix (click)="toggleNewPasswordVisibility()">
                                    <mat-icon>{{ newPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
                                </button>
                                <mat-error
                                    *ngIf="changePasswordForm.get('newPassword')?.hasError('required') && changePasswordForm.get('newPassword')?.touched">
                                    New Password is required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between" class="mb-2">
                        <div class="mt-3 red-star">Confirm Password</div>
                        <div fxLayout="column" class="w-70">
                            <mat-form-field>
                                <input matInput [type]="confirmPasswordVisible ? 'text' : 'password'"
                                    placeholder="Enter Confirm Password" formControlName="confirmNewPassword">
                                <button mat-icon-button matSuffix (click)="toggleConfirmPasswordVisibility()">
                                    <mat-icon>{{ confirmPasswordVisible ? 'visibility' : 'visibility_off' }}</mat-icon>
                                </button>
                                <mat-error
                                    *ngIf="changePasswordForm.get('confirmNewPassword')?.hasError('required') && changePasswordForm.get('confirmNewPassword')?.touched">
                                    Confirm new password is required.
                                </mat-error>
                            </mat-form-field>
                            <mat-error class="password-confirm ms-2"
                                *ngIf="
                                changePasswordForm?.value?.confirmNewPassword &&
                                (changePasswordForm?.value?.newPassword !== changePasswordForm?.value?.confirmNewPassword)">
                                New password and Confirm Password do not match.
                            </mat-error>
                        </div>
                    </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                    <button type="submit" [disabled]="changePasswordForm.invalid" (click)="saveChangePassForm()"
                        class="mt-4" mat-raised-button color="primary">Save</button>
                </div>
            </div>
        </mat-card>
    </form>
</div>