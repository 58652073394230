import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesMenu } from '../utilities/menu';
import { DashboardControlService } from '../services/dashboard-control.service';
import { BrandBuService } from '../services/brand-bu.service';
import { Subscription } from 'rxjs';
import { PermissionService } from '../services/permission.service';
import { findChildRouteMapping } from '../utilities/find-child-route';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit, OnDestroy {
  currentPage = 'dashboard';
  user: any;
  isUserLoggedIn:boolean=true;
  RoutesMenu = RoutesMenu;
  showWelcomeData:boolean=false;
  expandAccountSidebar = true;
  expandDeliverySidebar = true;
  routerEvent: Subscription;
  findChildRouteMapping = findChildRouteMapping;

  constructor(
    private router:Router,
    private dashboardControlService:DashboardControlService,
    private brandBuService:BrandBuService,
   public permissionService: PermissionService
  ) { }

  ngOnInit(): void {
    this.routerEvent = this.router.events.subscribe((val: any) => {
      if (val.url) {
        this.currentPage = val.url?.substring(1, val.url?.length);
        if (!(this.currentPage?.includes('accounts'))) {
          this.expandAccountSidebar = false;
        } else if (!(this.currentPage?.includes('delivery'))) {
          this.expandDeliverySidebar = false;
        }
        if (["/login","/",""].includes(val.url)) {
          this.user = '';
          this.isUserLoggedIn = false;
        } else {
          this.isUserLoggedIn = true;
          this.user = sessionStorage.getItem('userName');
        }
        if (["/otp","/",""].includes(val.url)) {
          this.user = '';
          this.isUserLoggedIn = false;
        }
        
      }
      
    });
  }
  
  checkIfCurrentPage(route: string) {
    return window.location.href.includes(route);
  }
  activateRoute(url:string){
    this.currentPage=url;
    this.dashboardControlService._isComingFromSidebar =true;
    this.router.navigate([url]);
  }
  openTableRowDetails(event:any){
    this.brandBuService._brandBuFormData = event.item;
    this.router.navigate([RoutesMenu.accountsDetails]);
    // this.createContactAccountsOutput.emit(actionTakenData);

  }
  signOut(){
    const isConfirmed = window.confirm("Are you sure you want sign out?");
    if (isConfirmed) {
    sessionStorage.clear(); // Remove u  ser data from local storage
    this.user = '';
    this.isUserLoggedIn = false;
    this.router.navigate(['/login'],{replaceUrl:true});
    }
    else{
      console.log("Sign Out canceled by the user.");

    }
  } 
  
  ngOnDestroy(): void {
    this.routerEvent.unsubscribe();
  }

 
}


// "/delivery/order": "Order.GetAllOrder" || "UI.Delivery.Orders",
// "delivery/order/details":"Order.GetAllOrderById",
// "/delivery/campaigns": "Campaign.GetAllCampaign" ||  "UI.Delivery.Campaigns",
// "/delivery/placement": "Placement.GetAllPlacement" ||"UI.Delivery.Placements",