import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'ad-tech';
  constructor(private loginService: LoginService){

  }
  ngOnInit(): void {
    const tokenExpiryTime = sessionStorage.getItem('tokenExpiresAt');
    if (tokenExpiryTime) {
      this.loginService.setStartTimer();  // Continue the timer even after page refresh
    }
  }
}
