import { Injectable } from '@angular/core';
import { ApplicationApiService } from './application-api.service';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomAudienceService {
  constructor(private applicationApiService: ApplicationApiService) { }
  public _roleFormData: any;

  getCustomFormData() {
    return this._roleFormData;
  }

  getCustomAudience() {
    return this.applicationApiService.getData("CustomAudience", "")
  }

  createCustomAudience(SegmentName: any, SegmentDescription: any, file: any) {
     const formData = new FormData();
    formData.append('SegmentName', SegmentName);
    formData.append('SegmentDescription', SegmentDescription || '');
    if (file) {
        formData.append('file', file);
    }  
      return this.applicationApiService.postDataMultiPart('CreateCustomAudience', formData);
  }

  UpdateCustomAudience(id: any, SegmentName: any, SegmentDescription: any, file: any) {
    const formData = new FormData();
    formData.append('SegmentName', SegmentName);
    formData.append('SegmentDescription', SegmentDescription || '');
    formData.append('id',id);
    if (file) {
        formData.append('file', file);
    }  
    return this.applicationApiService.putDataMultiPart('UpdateCustomAudience', formData);
}
  deleteCustomAudience(id: any) {
    return this.applicationApiService.deleteData("CustomAudience/" + id)
  }
}
