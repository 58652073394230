import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { AccountsService } from 'src/app/services/accounts.service';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { DashboardControlService } from 'src/app/services/dashboard-control.service';
import { DropdownMapper } from 'src/app/utilities/dropdown-mapping';
import { filterOnTable } from 'src/app/utilities/tablet-filter';

@Component({
  selector: 'app-campaign-yesterday-data',
  templateUrl: './campaign-yesterday-data.component.html',
  styleUrls: ['./campaign-yesterday-data.component.scss']
})
export class CampaignYesterdayDataComponent {
  apiResponse: boolean = false;
  dataSourceClone: any

  displayedColumnsCampaigns: any;
  dataSourceCampaigns: any;

  displayedColumnsbanners: any;
  dataSourceBanners: any;

  accountUser = [];

  constructor(private router: Router,
    private accountsService: AccountsService,
    private authGuardService: AuthGuardService,
    private dropdownMapper: DropdownMapper,
    private dashboardControlService: DashboardControlService) { }

  ngOnInit(): void {
    this.displayedColumnsCampaigns = [
      "Date",
      "Order ID",
      "Order Name",
      "Campaign ID",
      "Campaign Name",
      "Schedule ID",
      "Daily Requirement",
      "Scheme",
      "Sum Of Impressions",
      "Sum Of Clicks",
      "CTR"
      
    ];
   
    const today = moment();
    const yesterday:any = today.subtract(1, 'days');
    forkJoin([
      // this.dashboardControlService.getDashboardList(),
      this.dashboardControlService.getDashboardListCampaign(
        moment(yesterday._d).format("YYYY-MM-DD"),
        moment(yesterday._d).format("YYYY-MM-DD")

      ),
    ]).subscribe((res: any) => {
      const mappedDataSource: any = [];
      res[0]?.forEach((element: any) => {
        mappedDataSource.push({
          "Date": moment(element.date).format("YYYY-MM-DD"),
          "Order ID":element.orderID,
          "Order Name":element.orderName,
          "Campaign ID":element.campaignID,
          "Campaign Name":element.campaignName,
          "Schedule ID": element.scheduleID,
          "Daily Requirement": element.dailyRequirement,
          "Scheme": element.scheme,
          "Sum Of Impressions":element.sumOfImpressions,
          "Sum Of Clicks": element.sumOfClicks,
          "CTR":element.ctr,
          data: element
        })
        
      });
      
      this.dataSourceClone = JSON.parse(JSON.stringify(mappedDataSource));
      this.dataSourceCampaigns = new MatTableDataSource(mappedDataSource);
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSourceCampaigns.data = filterOnTable(this.dataSourceClone, filterValue);

  }
}
