export const filterOnTable = (dataSourceClone: any, filterValue: string) => {
  const fList: any = [];
  dataSourceClone.forEach((item: any) => {
    let isValueAvailable = false;
    Object.keys(item).forEach((ele: any) => {
      if (
        (
          typeof (item[ele]) === 'object' &&
          (
            item[ele]?.['codeTypeName']?.toString()?.toLowerCase()?.includes(filterValue?.trim()?.toLowerCase()) ||
            item[ele]?.['codeDescription']?.toString()?.toLowerCase()?.includes(filterValue?.trim()?.toLowerCase()) ||
            item[ele]?.['codeName']?.toString()?.toLowerCase()?.includes(filterValue?.trim()?.toLowerCase())
          )
        )
        ||
        (
          typeof (ele) !== 'object' &&
          item[ele]?.toString()?.toLowerCase()?.includes(filterValue.trim().toLowerCase())
        )
      ) {
        isValueAvailable = true;
      }
    });
    if (isValueAvailable) {
      fList.push(item);
    }
  });
  return fList;
}