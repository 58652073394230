import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesMenu } from 'src/app/utilities/menu';
import { MatDialog } from '@angular/material/dialog';
import { CustomAudienceService } from 'src/app/services/custom-audience.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { result } from 'lodash';

@Component({
  selector: 'app-create-custom-audience',
  templateUrl: './create-custom-audience.component.html',
  styleUrls: ['./create-custom-audience.component.scss']
})
export class CreateCustomAudienceComponent implements OnInit {
  customAudienceForm: FormGroup<any> = new FormGroup({
    SegmentName: new FormControl(),
    SegmentDescription: new FormControl()
  })
  showEditOption: boolean = true;
  file: File | null = null;
  uploadedFile: any;
  showEdit: boolean = false;
  selectedFile: File | null = null;
  isSaveDisable: boolean = false;
  customId: any;
  selectedFiles: any[] = [];
  filesDisplayedColumns: any[] = [];
  filesDataSource: MatTableDataSource<any>;
  allData: any;
  fileNames: any
  CustomAudienceId: number | undefined;
  isFileSelected = false;
  showSpinner: boolean = false;

  constructor(private router: Router, private fb: FormBuilder,
    public dialog: MatDialog, private route: ActivatedRoute,
    public customAudience: CustomAudienceService, private sanitizer: DomSanitizer, private http: HttpClient
  ) {

  }
  ngOnInit() {
    this.router.events.subscribe((res: any) => {
      this.CustomAudienceId = res?.routerEvent?.url?.split("/")?.[3];
    })
    this.filesDisplayedColumns = [
      'CustomId',
      'File Name',
      'Status',
      'Remark',
      'Remove Segment',
      'Process',
      'Delete'
    ];

    setTimeout(() => {
      this.loadData();
      this.showEditOption = this.CustomAudienceId == undefined ? false : true;
    }, 10);
  }

  loadData(CustomId = 0) {
    let formData: any = null;
    if (CustomId != 0) {
      this.CustomAudienceId = CustomId;
    }
    if (this.CustomAudienceId) {
      if (formData == null) {
        this.customAudience.getCustomAudienceById(this.CustomAudienceId).subscribe((res: any) => {
          formData = { data: res[0] };
          this.allData = formData;

          const fileArray = JSON.parse(formData.data.file_path);
          const id = formData.data.id;

          this.fileNames = fileArray.map((f: any) => {
            if (f.FilePath) {
              const path = f.FilePath.split('/').pop();
              return { fileName: path, status: f?.Status };
            } else {
              return { fileName: null, status: 'unknown' };
            }
          });

          if (Array.isArray(this.fileNames)) {
            this.fileNames.map((x: any) => {
              x.customId = id;
              x.process = 'Pending';
              x.Action = 'delete';
              return x;
            });
          }

          if (this.fileNames && this.fileNames[0]?.fileName !== '') {
            this.isSaveDisable = true;
            this.filesDataSource = new MatTableDataSource(this.fileNames);
          }

          this.customAudienceForm = this.fb.group({
            SegmentName: [formData?.data?.segment_name || null, Validators.required],
            SegmentDescription: [formData?.data?.segment_description || null, Validators.required],
            file_path: [formData?.data?.file_path],
            id: [formData?.data?.id || null],
          });

          if (this.showEditOption) {
            this.customAudienceForm.disable();
            this.customAudienceForm.get('file_path')?.enable();
          }
        });
      }
    }
  }

  getFileName() {
    const filePath = this.customAudienceForm.get('file_path')?.value;
    return filePath ? filePath.split('/').pop() : null;
  }

  getSafeUrl() {
    const filePath = this.customAudienceForm.get('file_path')?.value;
    return filePath ? this.sanitizer.bypassSecurityTrustUrl(filePath) : '';
  }
  saveRoleForm() {

    this.customAudienceForm.enable();
    if (this.customAudienceForm.invalid) {
      return;
    }
    const requestBody: any = {
      "id": this.customAudienceForm.value.id || this.customId,
      "SegmentName": this.customAudienceForm.value.SegmentName,
      "SegmentDescription": this.customAudienceForm.value.SegmentDescription || null,
      "files": this.selectedFiles,
    };

    let segmentFiles = this.fileNames = this.customAudience._roleFormData?.data?.files?.map((x: any) => {
      return { filePath: x.filepath.split('/').pop(), status: x.status };
    })

    const uploadReqbody: any = {
      "audienceId": 0,
      "segmentFiles": segmentFiles,
      "delete": false
    }

    const { id, SegmentName, SegmentDescription, file } = requestBody;
    this.showSpinner = true;
    if (!this.CustomAudienceId) {
      this.customAudience.createCustomAudience(SegmentName, SegmentDescription, this.selectedFiles).subscribe((res: any) => {
        this.customId = res;
        this.customAudience.getCustomAudience().subscribe((response: any) => {
          this.customAudience._roleFormData = response.find((x: any) => x.id == res)
          this.loadData();
          this.showSpinner = false;

        });
        this.router.navigate([RoutesMenu.CreatecustomAudience + `/${res}`]);
        this.showEditOption = true;
      });
    } else {
      this.customAudience.UpdateCustomAudience(id, SegmentName, SegmentDescription, this.selectedFiles).subscribe((res: any) => {
        uploadReqbody.audienceId = res;
        this.customAudience.getCustomAudience().subscribe((response: any) => {
          this.customAudience._roleFormData = { data: response.find((x: any) => x.id == res) }
          this.loadData(res);
          this.customAudienceForm?.get('file_path')?.reset();
          this.selectedFiles = [];
          this.showSpinner = false;
          this.isFileSelected = false;

        })
        // this.customAudience.uploadCustomAudience([uploadReqbody]).subscribe((response) => {});
      })
    }
  }

  backToRolesPage() {
    this.router.navigate([RoutesMenu.customAudience])
  }
  OnEditEnable() {
    this.isSaveDisable = false;
    this.customAudienceForm.enable();
    this.customAudienceForm.get('file_path')?.enable(); // Explicitly enable the file path input

  }
  delete() {
    const isConfirmed = window.confirm("Are you sure you want to delete this data?");
    if (isConfirmed) {
      this.customAudience.deleteCustomAudience(this.customAudience._roleFormData.Id || this.customAudience._roleFormData.id).subscribe(response => {
        this.router.navigate([RoutesMenu.customAudience]);

      });
    } else {
    }
  }

  goToUserBox() {
    this.router.navigate([RoutesMenu.customAudience]);
  }

  onFileChange(event: any) {
    const files = event.target.files;
    this.isFileSelected = true;
    if (files) {
      this.selectedFiles.push(...files);
    }
    setTimeout(() => {
      alert('File is chosen. Now please upload the file.');
    }, 300);
  }
  downloadFile() {
    const filePath = this.customAudienceForm.get('file_path')?.value;
    if (filePath) {
      const link = document.createElement('a');
      link.href = filePath;
      link.download = '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
    }
  }
}
