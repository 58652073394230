import { Injectable } from '@angular/core';
import { ApplicationApiService } from './application-api.service';
import { PlacementService } from './placement.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class BannerService {
  _bannerId: string;
  private banners = [
    { channelId: 'canara', bankPlacementId: '01', name: 'MobileApp - Pre Login-1500X500', bannerMain: 'Canara' },
    { channelId: 'canara', bankPlacementId: '02', name: 'MobileApp - Home-1500X500', bannerMain: 'Canara' },
    { channelId: 'canara', bankPlacementId: '03', name: 'Internet Banking - Pre Login-  746X965', bannerMain: 'Canara' },
    { channelId: 'canara', bankPlacementId: '04', name: 'Internet Banking - Home-1500X500', bannerMain: 'Canara' },
    { channelId: 'canara', bankPlacementId: '05', name: 'Corporate App - Pre Login-1500X500', bannerMain: 'Canara' },
    { channelId: 'canara', bankPlacementId: '06', name: 'Corporate App - Home-1500X500', bannerMain: 'Canara' },
    { channelId: 'canara', bankPlacementId: '07', name: 'Internet Banking - Popup-1150X960', bannerMain: 'Canara' },
    { channelId: 'bob', bankPlacementId: '01', name: 'BOB Account', bannerMain: 'BoB' },
    { channelId: 'bob', bankPlacementId: '02', name: 'BOB Save', bannerMain: 'BoB' },
    { channelId: 'bob', bankPlacementId: '03', name: 'BOB Invest', bannerMain: 'BoB' },
    { channelId: 'bob', bankPlacementId: '04', name: 'BOB Borrow', bannerMain: 'BoB' }
  ];
  public _bannerFormData: any;
  constructor(private applicationApiService: ApplicationApiService, private placementService: PlacementService) { }


  getBanners() {
    return this.banners;
  }

  getBannerById(id: string) {
    return this.applicationApiService.getData(`Banner/${id}`, "");
  }

  getData() {
    return this._bannerFormData;
  }
  setData(data: any) {
    this._bannerFormData = data;
  }
  getBannerData() {
    const queryParams = {
      "pageNumber": 1,
      "rows": 1000,
      "sortingColumn": "none",
      "sortType": "ASC"
    }
    return this.applicationApiService.getData("Banner", queryParams)
  }


  getBannerApprovalDta(channels?: string, createdBy?: string) {
    const params: any = {};
    if (channels) params.channels = channels;
    if (createdBy) params.createdBy = createdBy;
    return this.applicationApiService.getData("Banner/Approved", params);
  }
  
  getBannerRejectedDta(filter?: string,createdBy?: string) {
    const params: any = {};
    if (filter) params.filter = filter;
    if (createdBy) params.createdBy = createdBy;
    return this.applicationApiService.getData("Banner/Rejected", params)
  }
  getBannerNtApprovedDta(filter?: string,createdBy?: string) {
    const params: any = {};
    if (filter) params.filter = filter;
    if (createdBy) params.createdBy = createdBy;
    return this.applicationApiService.getData("Banner/NotApproved", params);
  }
  getBannerFormatDropdown() {
    return this.applicationApiService.getData("Code/CodeTypeName/Banner%20Ad%20Format", "")
  }
  getBannerSizeDropdown() {
    return this.applicationApiService.getData("Code/CodeTypeName/Banner%20Size", "")
  }
  saveBannerData(body: any) {
    return this.applicationApiService.postData("Banner", body)
  }
  getPlacementIdFromBannerId(bannerId: string) {
    return this.applicationApiService.getData("Placement/Banner/" + bannerId, "");
  }
  getBannerIdFromPlacementId(placementId: string) {
    return this.applicationApiService.getData("Banner/Placement/" + placementId, "");
  }
  getPlacementByBannerId(bannerId: any) {
    return this.applicationApiService.getData("Placement/Banner/" + bannerId, "");

  }
  savePlacementAndBanner(bannerId: string, placementId: string) {
    const url = `Banner/Placement/${bannerId}/${placementId}`;
    return this.applicationApiService.postData(url, {});
  }

  // savePlacementAndBanner(bannerId: string, placementId: string) {
  //   return this.applicationApiService.postData('Banner/Placement?bannerId=' + bannerId + '&placementId=' + placementId, {});
  // }

  
  updateBannerData(body: any) {
    return this.applicationApiService.putData("Banner", body)
  }

  AdRequestData(channelId: any, bankPlacementId: string): Observable<any> {
    const body = {
      "adUnitId": "",
      "adPoistion": "",
      "channelId": channelId,
      "userId": "abctt56",
      "gaid": "",
      "lat": "",
      "long": "",
      "deviceManufacturer": "",
      "deviceBrand": "",
      "mcc": "",
      "mncCode": "",
      "language": "",
      "additionalParams": "",
      "mobileVersion": "",
      "networkSpeed": "",
      "networkType": "",
      "timestamp": "",
      "category": "",
      "bankPlacementId": bankPlacementId
    };
    return this.applicationApiService.adRequestDataPost(body)
  }
  deleteBannerData(id: any) {
    return this.applicationApiService.deleteData("Banner/" + id)
  }
  saveImageFile(accountId: string, file: any) {
    return this.applicationApiService.saveImage(`Banner/File?accountId=${accountId}`, file);
  }
  getImageFile(fileName: string, accountId: string) {
    const queryParams = {
      "fileName": fileName,
      "accountId": accountId
    }
    return this.applicationApiService.getImage("Banner/File", queryParams);
  }

  getBannerAccountInPlacement(accountId: number) {
    return this.applicationApiService.getData("Banner/Account/" + accountId, "");
  }

  getBannersForApproval() {
    const queryParams = {
      "pageNumber": 1,
      "rows": 1000,
      "sortingColumn": "None",
      "sortType": "ASC"
    };
    return this.applicationApiService.getData("Approval", queryParams);
  }

  postBannerApproval(channelId: any, bannerId: any, status: any, comment: any, body: any) {
    return this.applicationApiService.postData(`Banner/Approved/${channelId + '?bannerId=' + bannerId + '&status=' + status + '&comment=' + comment}`, body);
  }

  putBannerApproval(body: any) {
    return this.applicationApiService.putData('Approval', body);
  }
  getCodeTypeName(codeTypeName: string, codeDescriptionPattern: string = '') {
    let url = `Code/CodeTypeName/${encodeURIComponent(codeTypeName)}`;
    if (codeDescriptionPattern) {
        url += `?codeDescriptionPattern=${encodeURIComponent(codeDescriptionPattern)}`;
    }
    return this.applicationApiService.getData(url,'');
}

// getGeoCountryDropdown() {
//   return this.applicationApiService.getData("Code/CodeTypeName/Geo-Country", "")
// }

// getGeoStateDropdown() {
//   return this.applicationApiService.getData("Code/CodeTypeName/Geo-State", "")
// }

// getGeoDistrictDropdown() {
//   return this.applicationApiService.getData("Code/CodeTypeName/Geo-District", "")
// }

// getGeoPinCodeDropdown() {
//   return this.applicationApiService.getData("Code/CodeTypeName/Geo%20Pin%20Code", "")
// }

}
