<div fxLayout="row" fxLayoutAlign="space-between">
    <div class="back-button">
        <button (click)="goToUserBox()" mat-raised-button class="blue-color mt-1"><mat-icon
                class="mb-1">navigate_before</mat-icon>Back</button>
    </div>
    <div class="adunit-button" *ngIf="showEditOption">
        <button mat-raised-button color="primary" (click)="OnEditEnable()" class="me-2 mt-2">Edit</button>
        <button (click)="delete()" mat-raised-button color="accent" class="me-2 mt-2">Delete</button>

    </div>
</div>
<div class="create-role-container">
    <form [formGroup]="customAudienceForm">
        <mat-card  [ngClass]="customAudience._roleFormData ? 'width-80' : 'width-50'">
            <div class="create-role">
                <div>
                    <h2>{{showEditOption ? 'Edit Custom Audience' : 'Create Custom Audience'}}</h2>
                </div>
                <div class="role-container">
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
                        <div class="mt-3 red-star">Segment Name</div>
                        <div fxLayout="column" class="w-70">
                            <mat-form-field>
                                <input matInput placeholder="Enter Segment Name" formControlName="SegmentName">
                                <mat-error *ngIf="customAudienceForm?.get('SegmentName')?.hasError('required')">
                                    Segment name is required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
                        <div class="red-star">Segment Description</div>
                        <div fxLayout="column" class="w-70">
                            <mat-form-field>
                                <input matInput placeholder="Enter Segment Description"
                                    formControlName="SegmentDescription">
                                <mat-error *ngIf="customAudienceForm?.get('SegmentDescription')?.hasError('required')">
                                    Segment description is required.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <button type="submit" [disabled]="customAudienceForm.invalid || isSaveDisable"
                            (click)="saveRoleForm()" class="mt-4" mat-raised-button color="primary">Save</button>
                    </div>
                </div>

                <mat-divider class="mt-4"></mat-divider>

                
                <div *ngIf="showSpinner" class="spinner-size" fxLayout="row" fxLayoutAlign="center center">
                    <mat-spinner></mat-spinner>
                </div>
                <div class="role-container" *ngIf="!showSpinner">
                    <div *ngIf="showEditOption" class="mt-4" fxLayout="row" fxFlexFill fxLayoutAlign="space-between">
                        <div class="mt-3">File Upload</div>
                        <div  class="w-70">
                            <div  fxLayout="row" fxLayoutAlign="space-between center" class="upload-button-radius">
                                <input type="file" class="file-input" (change)="onFileChange($event)" accept=".csv,.txt"
                                    id="file"
                                    [attr.disabled]="customAudienceForm.get('file_path')?.disabled ? true : null"
                                    multiple />
                                <div fxLayout="row" fxLayoutAlign="space-between center">
                                    <button type="submit"   [disabled]="!isFileSelected"  mat-raised-button color="primary" class="ms-5" (click)="saveRoleForm()">Upload</button>

                                </div>
                            </div>
                            <mat-error>
                                * Please select one file at a time and upload
                            </mat-error>
                            
                        </div>
                    </div>
                </div>

            </div>
        </mat-card>
    </form>
</div>
<div class="create-role-container" *ngIf="showEditOption">
    <mat-card>
        <app-table class="special-table" [displayedColumns]="filesDisplayedColumns" [dataSource]="filesDataSource"
            [slider]="false" [hidePagination]="true">
        </app-table>
    </mat-card>
</div>
