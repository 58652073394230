import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent {
  constructor(public dialogRef: MatDialogRef<SessionTimeoutComponent>) {}

  continueSession() {
    this.dialogRef.close('continue');
  }

  endSession() {
    this.dialogRef.close('end');
  }
}
