// export const BannerDropdown: any = {
//     '0': {
//         type: 'image',
//         size: '1500x500',
//         cssClassName: 'bg_1500x500',
//         config: {            
//             format: 'jpg',
//             weight: 500000, //500kb
//             // weight: 150000, //150kb

//         },
//         fields: ['landingPage', 'url']
//     },
//     '1': {
//         type: 'image',
//         size: '320x480',
//         cssClassName: 'bg_320x480',
//         config: {            
//             format: 'jpg',
//             weight: 500000, //500kb

//             // weight: 150000, //150kb
//         },
//         fields: ['landingPage', 'url']

//     },
//     '2': {
//         type: 'image',
//         size: '1440x528',
//         cssClassName: 'bg_1440x528',
//         config: {            
//             format: 'jpg',
//             weight: 500000, //500kb

//             // weight: 150000, //150kb
//         },
//         fields: ['landingPage', 'url']

//     },
//     '3': {
//         type: 'image',
//         size: '1440x720',
//         cssClassName: 'bg_1440x720',
//         config: {            
//             format: 'jpg',
//             weight: 500000, //500kb

//             // weight: 150000,//150kb
//         },
//         fields: ['landingPage', 'url']

//     },
//     '4': {
//         type: 'logo',
//         size: '200x200',
//         cssClassName: 'bg_200x200',
//         config: {            
//             format: 'jpg',
//             weight: 500000, //500kb

//             // weight: 50000, //50kb
//         },
//         fields: ['landingPage', 'url']

//     },
//     '5': {
//         type: 'image',
//         size: '600x400',
//         cssClassName: 'bg_600x400',
//         config: {            
//             format: 'jpg',
//             weight: 500000, //500kb

//             // weight: 150000, //150kb
//         },
//         fields: ['landingPage', 'url', 'heading']

//     },
//     '6': {
//         type: 'image',
//         size: '1080x1920',
//         cssClassName: 'bg_1080x1920',
//         config: {            
//             format: 'jpg',
//             weight: 500000, //500kb

//             // weight: 100000, //100kb
//         },
//         fields: ['landingPage', 'url', 'heading', 'subheading']
//     },
//     '7': {
//         type: 'logo',
//         size: '200x200',
//         cssClassName: 'bg_200x200',
//         config: {            
//             format: 'jpg',
//             weight: 500000, //500kb

//             // weight: 2000000, //2000kb
//         },
//         fields: ['landingPage', 'url', 'heading', 'subheading']
//     },
//     '8': {
//         type: 'image',
//         size: '746x965',
//         cssClassName: 'bg_746x965',
//         config: {            
//             format: 'jpg',
//             weight: 500000, //500kb

//             // weight: 2000000, //2000kb
//         },
//         fields: ['landingPage', 'url']
//     },
//     '9': {
//         type: 'image',
//         size: '1150x960',
//         cssClassName: 'bg_1150x960',
//         config: {            
//             format: 'jpg',
//             weight: 500000, //500kb

//             // weight: 2000000, //2000kb
//         },
//         fields: ['landingPage', 'url']
//     }

// }


export const BannerDropdown: any = {
    '0': {
        type: 'image',
        size: '1500x500',
        cssClassName: 'bg_1500x500',
        config: {
            format: 'jpg',
            weight: 500000, //500kb
            // weight: 150000, //150kb

        },
        fields: ['landingPage', 'url']
    },
    '1': {
        type: 'image',
        size: '320x480',
        cssClassName: 'bg_320x480',
        config: {
            format: 'jpg',
            weight: 500000, //500kb

            // weight: 150000, //150kb
        },
        fields: ['landingPage', 'url']

    }, '2': {
        type: 'logo',
        size: '200x200',
        cssClassName: 'bg_200x200',
        config: {
            format: 'jpg',
            weight: 500000, //500kb

            // weight: 50000, //50kb
        },
        fields: ['landingPage', 'url']

    },
    '3': {
        type: 'image',
        size: '1440x528',
        cssClassName: 'bg_1440x528',
        config: {
            format: 'jpg',
            weight: 500000, //500kb

            // weight: 150000, //150kb
        },
        fields: ['landingPage', 'url']

    },
    '4': {
        type: 'image',
        size: '1440x720',
        cssClassName: 'bg_1440x720',
        config: {
            format: 'jpg',
            weight: 500000, //500kb

            // weight: 150000,//150kb
        },
        fields: ['landingPage', 'url']

    },

    '5': {
        type: 'image',
        size: '746x965',
        cssClassName: 'bg_746x965',
        config: {
            format: 'jpg',
            weight: 500000, //500kb

            // weight: 2000000, //2000kb
        },
        fields: ['landingPage', 'url']
    },
    '6': {
        type: 'image',
        size: '1150x960',
        cssClassName: 'bg_1150x960',
        config: {
            format: 'jpg',
            weight: 500000, //500kb

            // weight: 2000000, //2000kb
        },
        fields: ['landingPage', 'url']
    }
    ,
    '7': {
        type: 'image',
        size: '600x400',
        cssClassName: 'bg_600x400',
        config: {
            format: 'jpg',
            weight: 500000, //500kb

            // weight: 150000, //150kb
        },
        fields: ['landingPage', 'url', 'heading']

    },
    '8': {
        type: 'image',
        size: '1080x1920',
        cssClassName: 'bg_1080x1920',
        config: {
            format: 'jpg',
            weight: 500000, //500kb

            // weight: 100000, //100kb
        },
        fields: ['landingPage', 'url', 'heading', 'subheading']
    },
    '9': {
        type: 'logo',
        size: '200x200',
        cssClassName: 'bg_200x200',
        config: {
            format: 'jpg',
            weight: 500000, //500kb

            // weight: 2000000, //2000kb
        },
        fields: ['landingPage', 'url', 'heading', 'subheading']
    },
    '10': {
        type: 'image',
        size: '1000x400',
        cssClassName: 'bg_1000x400',
        config: {
            format: 'jpg',
            weight: 500000, //500kb
            // weight: 150000, //150kb

        },
        fields: ['landingPage', 'url']
    },
    '11': {
        type: 'image',
        size: '1000x850',
        cssClassName: 'bg_1000x850',
        config: {
            format: 'jpg',
            weight: 500000, //500kb
            // weight: 150000, //150kb

        },
        fields: ['landingPage', 'url']
    },
    '12': {
        type: 'image',
        size: '1500x500',
        cssClassName: 'bg_1500x500',
        config: {
            format: 'jpg',
            weight: 500000, //500kb
            // weight: 150000, //150kb

        },
        fields: ['landingPage', 'url']
    },
    '13': {
        type: 'image',
        size: '800x1040',
        cssClassName: 'bg_1500x500',
        config: {
            format: 'jpg',
            weight: 500000, //500kb
            // weight: 150000, //150kb

        },
        fields: ['landingPage', 'url']
    },
    '14': {
        type: 'image',
        size: '1400x1050',
        cssClassName: 'bg_1400x1050',
        config: {
            format: 'jpg',
            weight: 500000, //500kb
            // weight: 150000, //150kb

        },
        fields: ['landingPage', 'url']
    },
}




export const AdFormatDropdown = [
    { key: '0', codeName: 'SingleImage_1500x500' },
    { key: '1', codeName: 'SingleImage_320x480' },
    { key: '2', codeName: 'SingleImage_200x200' },
    { key: '3', codeName: 'SingleImage_1440x528' },
    { key: '4', codeName: 'SingleImage_1440x720' },


    { key: '5', codeName: 'SingleImage_746x965' },
    { key: '6', codeName: 'SingleImage_1150x960' },

    { key: '7', codeName: 'Offer/Reward Banner' },
    { key: '8', codeName: 'Story Ads' },
    { key: '9', codeName: 'VideoBanner_1500x500' },
    { key: '10', codeName: 'SingleImage_1000x400' },
    { key: '11', codeName: 'SingleImage_1000x850' },
    { key: '12', codeName:'SingleImage_Bob_1500x500'},
    { key: '13', codeName: 'SingleImage_800x1040' },
    { key: '14', codeName: 'SingleImage_1400x1050' }
];

// public enum AdFormat
// {
//     SingleImage_1500x500,
//     SingleImage_320x480,
//     SingleImage_200x200,
//     SingleImage_1440x528,
//     SingleImage_1440x720,
//     SingleImage_746X965,
//     SingleImage_1150X960,
//     OfferOrRewardBanner,
//     StoryAd,
//     VideoBanner_1500x500
// }


