import { Injectable, OnInit } from '@angular/core';
import { ApplicationApiService } from './application-api.service';
import { OrderService } from './order.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {
  _campaignId: string;
  _campaignFormData: any
  _campaignAddScheduleData: any

  get campaignAddScheduleData() {
    return this._campaignAddScheduleData;
  }
  set campaignAddScheduleData(data: any) {
    this._campaignAddScheduleData = data;
  }

  public notifyOnSaveCreatePlacement: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private applicationApiService: ApplicationApiService,
    private orderService: OrderService
  ) { }
  getCampaignList() {
    const queryParams = {
      "pageNumber": 1,
      "rows": 2000,
      "sortingColumn": "None",
      "sortType": "ASC"
    }
    return this.applicationApiService.getData("Campaign", queryParams);
  }

  getActiveCampaignReport() {
    return this.applicationApiService.getReportData("GetActiveCampaigns", "");
  }

  getCampaignData(dateFrom: any, dateTo: any, includeBannerData: any, campaignId: any) {
    const queryParam = {
      "dateFrom": dateFrom,
      "dateTo": dateTo,
      "includeBannerData": includeBannerData,
      "campaignId": campaignId
    }
    return this.applicationApiService.getReportData("GetAnalyticsCTRReportByCampaignId?", queryParam)
  }

  getCampaignById(id: string) {
    return this.applicationApiService.getData(`Campaign/${id}`, "");
  }
  getCampaignByOrderId() {
    return this.applicationApiService.getData(`Campaign/Order/${this.orderService._orderFormData?.Id}`, "");
  }
  saveCampaignList(body: any) {
    return this.applicationApiService.postData("Campaign", body);
  }
  updateCampaignList(body: any) {
    return this.applicationApiService.putData("Campaign", body);
  }
  deleteCampaign(id: number) {
    return this.applicationApiService.deleteData("Campaign/" + id);
  }
  getCampaignTypeDropdownList() {
    return this.applicationApiService.getData("code/codetypename/Campaign%20Type", "");
  }
  getCampaignDealTypeDropdownList() {
    return this.applicationApiService.getData("code/codetypename/Campaign%20Deal%20Type", "");
  }
  getCampaignPriorityDropdownList() {
    return this.applicationApiService.getData("code/codetypename/Campaign%20Priority", "");
  }
  getCampaignPricingDropdownList() {
    const queryParams = {
      "pageNumber": 1,
      "rows": 1000,
      "sortingColumn": "none",
      "sortType": "ASC"
    }
    return this.applicationApiService.getData("CampaignPricing", queryParams);
  }
  getCampaignAddSchedule() {
    return this.applicationApiService.getData("CampaignSchedule/campaign/" + this._campaignFormData?.Id, "");
  }
  saveCampaignAddSchedule(body: any) {
    return this.applicationApiService.postData("CampaignSchedule", body);
  }
  updateCampaignAddSchedule(body: any) {
    return this.applicationApiService.putData("CampaignSchedule", body);
  }
  deleteCampaignAddSchedule(id: number) {
    return this.applicationApiService.deleteData("CampaignSchedule/" + id);
  }
  getCampaignFOC() {
    return this.applicationApiService.getData("Campaign/totalFoc/" + this._campaignFormData?.Id, "")
  }
  saveCampaignFOC(body: any) {
    return this.applicationApiService.putData("Campaign/TotalFoc", body);
  }
  getCampaignFcap() {
    return this.applicationApiService.getData("Campaign/frequencyCap/" + this._campaignFormData?.Id, "")
  }
  saveCampaignFcap(body: any) {
    return this.applicationApiService.putData("Campaign/FrequencyCap", body);
  }


  saveDaytimeParting(body: any) {
    return this.applicationApiService.putData("Campaign/DaytimeParting", body);
  }
  getDaytimeParting() {
    return this.applicationApiService.getData("Campaign/daytimeParting/" + this._campaignFormData?.Id, "")
  }
  // getCampaignPlacement(){
  //   return this.applicationApiService.getData("Placement", "" )
  // }
  savePlacement(body: any) {
    return this.applicationApiService.postData("Placement", body);
  }
  getPlacemenByCampaignId() {
    return this.applicationApiService.getData("Placement/Campaign/" + this._campaignFormData?.Id, "")
  }
  get campaignFormData() {
    return this._campaignFormData;
  }
  set campaignFormData(data: any) {
    this._campaignFormData = data;
  }
}
