<div class="home-page-contaienr">
    <div class="rigel-header">
        <mat-toolbar color="primary">
            <button *ngIf="isUserLoggedIn" mat-icon-button class="example-icon"
                aria-label="Example icon-button with menu icon" (click)="drawer.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <div class="header-content" fxLayout="row" fxFlex="1 1 100%" fxLayoutAlign="space-between center">
                <div>Rigel by Cheggout</div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxLayout="column">
                        <div fxLayout="row">
                            <div *ngIf="user" class="welcome-text">Welcome {{user}} | </div>
                            <button style="border: 2px solid white" *ngIf="user" class="ms-2 " (click)="signOut()"
                                mat-stroked-button>Sign Out</button>
                        </div>
                    </div>
                    <div fxLayoutAlign="center center" class="cheggout-logo"><img src="../../assets/cheggout-logo.png"
                            alt=""> </div>
                </div>
            </div>
        </mat-toolbar>
    </div>

    <div class="cursor-bar">
        <mat-drawer-container class="example-container custom-drawer-container" autosize>
            <mat-drawer #drawer class="example-sidenav" mode="side" [opened]="isUserLoggedIn">
                <mat-list role="list">
                    <mat-accordion *ngIf="permissionService.canAccess('UI.Dashboard')">
                        <mat-expansion-panel hideToggle [expanded]="expandDeliverySidebar">
                            <mat-expansion-panel-header>
                                <mat-list-item role="listitem"
                                    (click)="activateRoute(findChildRouteMapping('UI.Dashboard'))">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <mat-icon>dashboard</mat-icon>
                                            <span>Dashboard</span>
                                        </div>
                                        <mat-icon class="arrow-padding gap">arrow_drop_down</mat-icon>
                                    </div>
                                </mat-list-item>
                            </mat-expansion-panel-header>
                            <div expansion-padding>
                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Dashboard.CampaignDataToday')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.campaignToday)}"
                                    (click)="activateRoute(RoutesMenu.campaignToday)">
                                    Campaign Data Today
                                </mat-list-item>

                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Dashboard.BannerDataToday')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.bannerToday)}"
                                    (click)="activateRoute(RoutesMenu.bannerToday)">
                                    Banner Data Today
                                </mat-list-item>

                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Dashboard.CampaignDataYesterday')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.campaignYesterday)}"
                                    (click)="activateRoute(RoutesMenu.campaignYesterday)">
                                    Campaign Data Yesterday
                                </mat-list-item>

                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Dashboard.BannerDataYesterday')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.bannerYesterday)}"
                                    (click)="activateRoute(RoutesMenu.bannerYesterday)">
                                    Banner Data Yesterday

                                </mat-list-item>
                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Dashboard.ActiveCampaignReport')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.activeCampaignReport)}"
                                    (click)="activateRoute(RoutesMenu.activeCampaignReport)">
                                    Active Campaign Report
                                </mat-list-item>
                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Dashboard.BannerQC')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.bannersQC)}"
                                    (click)="activateRoute(RoutesMenu.bannersQC)">
                                    Banners QC
                                </mat-list-item>

                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Dashboard.ActiveBanners')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.activeBanners)}"
                                    (click)="activateRoute(RoutesMenu.activeBanners)">
                                    Active Banners
                                </mat-list-item>
                                <!-- <mat-list-item role="listitem" [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.activeCampaignReport)}"
                                    (click)="activateRoute(RoutesMenu.campaigData)">
                                    Campaign Data
                                
                                </mat-list-item> -->
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="permissionService.canAccess('UI.Accounts')">
                        <mat-expansion-panel hideToggle [expanded]="expandAccountSidebar">
                            <mat-expansion-panel-header>
                                <mat-list-item role="listitem"
                                    (click)="activateRoute(findChildRouteMapping('UI.Accounts'))">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <mat-icon>account_circle</mat-icon>
                                            <span>Accounts</span>
                                        </div>
                                        <mat-icon class="arrow-padding gap">arrow_drop_down</mat-icon>
                                    </div>
                                </mat-list-item>
                            </mat-expansion-panel-header>

                            <!-- <mat-expansion-panel-header>
                                <mat-list-item role="listitem"
                                    [ngClass]="{'active-item':currentPage === RoutesMenu.accounts || currentPage === RoutesMenu.accountsCreate || currentPage.includes(RoutesMenu.accountsDetails)}"
                                    (click)="activateRoute(RoutesMenu.accounts);">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <mat-icon>account_circle</mat-icon>
                                            <span>Accounts</span>
                                        </div>
                                        <mat-icon class="arrow-padding gap">arrow_drop_down</mat-icon>
                                    </div>
                                </mat-list-item>
                            </mat-expansion-panel-header> -->
                            <div expansion-padding>
                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Accounts.Advertiser-Agency')"
                                    [ngClass]="{'active-item':currentPage === RoutesMenu.accountsAdvertiserAgency || currentPage === RoutesMenu.accountsCreate || currentPage.includes(RoutesMenu.accountsDetails)}"
                                    (click)="activateRoute(RoutesMenu.accountsAdvertiserAgency)"
                                    (clickOnTableRow)="openTableRowDetails($event)">
                                    Advertiser/Agency
                                </mat-list-item>

                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Accounts.BrandsBus')"
                                    [ngClass]="{'active-item': checkIfCurrentPage(RoutesMenu.accountsBrands)}"
                                    (click)="activateRoute(RoutesMenu.accountsBrands)"
                                    (clickOnTableRow)="openTableRowDetails($event)">
                                    Brands/BUs
                                </mat-list-item>

                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Accounts.Contacts')"
                                    [ngClass]="{'active-item': checkIfCurrentPage(RoutesMenu.accountsContact)}"
                                    (click)="activateRoute(RoutesMenu.accountsContact)">
                                    Contacts
                                </mat-list-item>

                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Accounts.Legal-Entities')"
                                    [ngClass]="{'active-item': checkIfCurrentPage(RoutesMenu.accountsLegalEntities)}"
                                    (click)="activateRoute(RoutesMenu.accountsLegalEntities)">
                                    Legal Entities
                                </mat-list-item>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <mat-accordion *ngIf="permissionService.canAccess('UI.Delivery')">
                        <mat-expansion-panel hideToggle [expanded]="expandDeliverySidebar">
                            <mat-expansion-panel-header>
                                <mat-list-item role="listitem"
                                    (click)="activateRoute(findChildRouteMapping('UI.Delivery'))">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <mat-icon>add_shopping_cart</mat-icon>
                                            <span>Delivery</span>
                                        </div>
                                        <mat-icon class="arrow-padding gap">arrow_drop_down</mat-icon>
                                    </div>
                                </mat-list-item>
                            </mat-expansion-panel-header>
                            <div class="expansion-padding">
                                <mat-list-item role="listitem" *ngIf="permissionService.canAccess('UI.Delivery.Orders')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.deliveryOrder) || currentPage === RoutesMenu.deliveryCreate}"
                                    (click)="activateRoute(RoutesMenu.deliveryOrder)">
                                    Orders
                                </mat-list-item>
                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Delivery.Campaigns')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.deliveryCampaign)}"
                                    (click)="activateRoute(RoutesMenu.deliveryCampaign)">
                                    Campaigns
                                </mat-list-item>
                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Delivery.Placements')"
                                    [ngClass]="{'active-item': checkIfCurrentPage(RoutesMenu.deliveryPlacements)}"
                                    (click)="activateRoute(RoutesMenu.deliveryPlacements)">
                                    Placement
                                </mat-list-item>
                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Delivery.Banners')"
                                    [ngClass]="{'active-item': checkIfCurrentPage(RoutesMenu.deliveryBanners)}"
                                    (click)="activateRoute(RoutesMenu.deliveryBanners)">
                                    Banners
                                </mat-list-item>
                                <!-- <mat-list-item role="listitem"
                                    [ngClass]="{'active-item': checkIfCurrentPage(RoutesMenu.deliveryBannersApprovals)}"
                                    (click)="activateRoute(RoutesMenu.deliveryBannersApprovals)">
                                    Banners Approvals
                                </mat-list-item> -->
                            </div>

                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="permissionService.canAccess('UI.Adunits')">
                        <mat-expansion-panel disabled>
                            <mat-expansion-panel-header>
                                <mat-list-item role="listitem"
                                    [ngClass]="{'active-item': checkIfCurrentPage(RoutesMenu.adUnits)}"
                                    (click)="activateRoute(RoutesMenu.adUnits)">
                                    <div fxLayout="row" fxLayoutAlign="flex-start center">
                                        <mat-icon>ad_units</mat-icon>
                                        <span>Ad-units</span>
                                    </div>
                                </mat-list-item>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="permissionService.canAccess('UI.Pricing')">
                        <mat-expansion-panel disabled>
                            <mat-expansion-panel-header>
                                <mat-list-item role="listitem"
                                    [ngClass]="{'active-item': checkIfCurrentPage(RoutesMenu.pricing)}"
                                    (click)="activateRoute(RoutesMenu.pricing)">
                                    <div fxLayout="row" fxLayoutAlign="flex-start center">
                                        <mat-icon>wallet</mat-icon>
                                        <span>Pricing</span>
                                    </div>
                                </mat-list-item>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="permissionService.canAccess('UI.CustomAudience')">
                        <mat-expansion-panel disabled>
                            <mat-expansion-panel-header>
                                <mat-list-item role="listitem"
                                    [ngClass]="{'active-item': checkIfCurrentPage(RoutesMenu.customAudience)}"
                                    (click)="activateRoute(RoutesMenu.customAudience)">
                                    <div fxLayout="row" fxLayoutAlign="flex-start center">
                                        <mat-icon>people</mat-icon>
                                        <span>Custom Audience</span>
                                    </div>
                                </mat-list-item>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion *ngIf="permissionService.canAccess('UI.BannerApprovals')">
                        <mat-expansion-panel disabled>
                            <mat-expansion-panel-header>
                                <mat-list-item role="listitem"
                                    [ngClass]="{'active-item': checkIfCurrentPage(RoutesMenu.deliveryBannersApprovals)}"
                                    (click)="activateRoute(RoutesMenu.deliveryBannersApprovals)">
                                    <div fxLayout="row" fxLayoutAlign="flex-start center">
                                        <mat-icon>playlist_add_check</mat-icon>
                                        <span>Banner Approvals</span>
                                    </div>
                                </mat-list-item>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <mat-accordion *ngIf="permissionService.canAccess('UI.Settings')">
                        <mat-expansion-panel hideToggle [expanded]="expandDeliverySidebar">
                            <mat-expansion-panel-header>
                                <mat-list-item role="listitem"
                                    (click)="activateRoute(findChildRouteMapping('UI.Settings'))">
                                    <div fxLayout="row" fxLayoutAlign="space-between center">
                                        <div fxLayout="row" fxLayoutAlign="space-between center">
                                            <mat-icon>settings</mat-icon>
                                            <span>Settings</span>
                                        </div>
                                        <mat-icon class="arrow-padding gap">arrow_drop_down</mat-icon>
                                    </div>
                                </mat-list-item>
                            </mat-expansion-panel-header>

                            <div expansion-padding>

                                <mat-list-item role="listitem" *ngIf="permissionService.canAccess('UI.Settings.Roles')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.roles)}"
                                    (click)="activateRoute(RoutesMenu.roles)">
                                    Roles
                                </mat-list-item>

                                <!-- <mat-list-item role="listitem" *ngIf="permissionService.canAccess('UI.Settings.Policy')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.policy)}"
                                    (click)="activateRoute(RoutesMenu.policy)">
                                    Policy
                                </mat-list-item> -->

                                <mat-list-item role="listitem" *ngIf="permissionService.canAccess('UI.Settings.Users')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.users)}"
                                    (click)="activateRoute(RoutesMenu.users)">
                                    Users
                                </mat-list-item>

                                <mat-list-item role="listitem"
                                    *ngIf="permissionService.canAccess('UI.Settings.Password')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.password)}"
                                    (click)="activateRoute(RoutesMenu.password)">
                                    Change Password
                                </mat-list-item>
                                <mat-list-item role="listitem" *ngIf="permissionService.canAccess('UI.Settings.Tenant')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.tenant)}"
                                    (click)="activateRoute(RoutesMenu.tenant)">
                                    Tenant
                                </mat-list-item>
                                <mat-list-item role="listitem"  *ngIf="permissionService.canAccess('UI.Settings.Configuration')"
                                    [ngClass]="{'active-item':checkIfCurrentPage(RoutesMenu.emailConfiguration)}"
                                    (click)="activateRoute(RoutesMenu.emailConfiguration)">
                                    Configuration
                                </mat-list-item>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-list>
            </mat-drawer>

            <div class="example-sidenav-content">
                <div fxLayout="column" fxFlexFill>
                    <router-outlet></router-outlet>
                </div>
            </div>
        </mat-drawer-container>
    </div>
</div>