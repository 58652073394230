import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-campaign-image-model',
  templateUrl: './campaign-image-model.html',
  styleUrls: ['./campaign-image-model.scss']
})
export class CampaignImageModel implements OnInit {
  imageUrl: string;
  constructor(
    public dialogRef: MatDialogRef<CampaignImageModel>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.imageUrl = data.imgUrl;
  }

  close(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {

  }

}
