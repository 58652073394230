import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorDialogComponent } from './shared/components/error-dialog/error-dialog.component';
import { AuthGuardService } from './services/auth-guard.service';
import { ForbiddenComponentComponent } from './forbidden-component/forbidden-component.component';
import { PasswordComponent } from './modules/settings/settings-folder/password/password.component';
import { CampaignTodayDataComponent } from './modules/dashboard/dashboard-for-banner-campaign/campaign-today-data/campaign-today-data.component';
import { CampaignYesterdayDataComponent } from './modules/dashboard/dashboard-for-banner-campaign/campaign-yesterday-data/campaign-yesterday-data.component';
import { BannerTodayDataComponent } from './modules/dashboard/dashboard-for-banner-campaign/banner-today-data/banner-today-data.component';
import { BannerYesterdayDataComponent } from './modules/dashboard/dashboard-for-banner-campaign/banner-yesterday-data/banner-yesterday-data.component';
import { ActiveCampaignReportComponent } from './modules/dashboard/dashboard-for-banner-campaign/active-campaign-report/active-campaign-report.component';
import { ActiveBannersComponent } from './modules/settings/settings-folder/active-banners/active-banners.component';
import { CampaignDataComponent } from './modules/dashboard/dashboard-for-banner-campaign/campaign-data/campaign-data.component';
import { CampaignReportComponent } from './modules/delivery/campaign-detail-page/campaign-report/campaign-report';
import { CreateCustomAudienceComponent } from './modules/custom-audience/create-custom-audience/create-custom-audience.component';
import { BannersQcComponent } from './modules/dashboard/dashboard-for-banner-campaign/banners-qc/banners-qc.component';

const routes: Routes = [
  { path: 'delivery', loadChildren: () => import('./modules/delivery/delivery.module').then(m => m.DeliveryModule) },
  { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'accounts', loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule) },
  { path: 'ad-units', loadChildren: () => import('./modules/ad-units/ad-units.module').then(m => m.AdUnitsModule) },
  { path: 'custom-audience', loadChildren: () => import('./modules/custom-audience/custom-audience.module').then(m => m.CustomAudienceModule) },
  { path: 'setting', loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule) },
  
  { path: 'custom/custom-create', component: CreateCustomAudienceComponent},

  { path: 'pricing', loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule) },
  { path: 'errors', component: ErrorDialogComponent },
  { path: 'forbidden-errors', component: ForbiddenComponentComponent, canActivate: [AuthGuardService] },
  { path: 'password', component: PasswordComponent, canActivate: [AuthGuardService] },
  { path: 'active-banners', component: ActiveBannersComponent, canActivate: [AuthGuardService] },
  { path: 'campaign-today', component: CampaignTodayDataComponent, canActivate: [AuthGuardService] },
  { path: 'campaign-yesterday', component: CampaignYesterdayDataComponent, canActivate: [AuthGuardService] },
  
  { path: 'banner-today', component: BannerTodayDataComponent, canActivate: [AuthGuardService] },
  { path: 'banner-yesterday', component: BannerYesterdayDataComponent, canActivate: [AuthGuardService] },
  { path: 'active-campaign', component: ActiveCampaignReportComponent, canActivate: [AuthGuardService] },

  { path: 'campaign-data', component: CampaignDataComponent, canActivate: [AuthGuardService] },
  { path: 'campaign-report', component:  CampaignReportComponent, canActivate: [AuthGuardService]},
  { path: 'banners-QC', component:  BannersQcComponent},


  { path: 'login', loadChildren: () => import('./login-page/login-page.module').then(m => m.LoginPageModule) },
  { path: 'otp', loadChildren: () => import('./otp/otp.module').then(m => m.OtpModule) },

  { path: '', loadChildren: () => import('./login-page/login-page.module').then(m => m.LoginPageModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
