import { Component, OnInit} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {
  changePasswordForm!: FormGroup<any>;
  currentPasswordVisible = false;
  newPasswordVisible = false;
  confirmPasswordVisible = false;
  constructor( private fb: FormBuilder,public changePasswordService: LoginService) { }

  ngOnInit() {
    const formData = this.changePasswordService._changePasswordData || null;
    this.changePasswordForm = this.fb.group({
      currentPassword: [formData?.["currentPassword"] || null, Validators.required],
      newPassword: [formData?.["newPassword"] || null, Validators.required],
      confirmNewPassword: [formData?.["confirmNewPassword"] || null, Validators.required],
    }, { validators: this.passwordMatchValidator() });
  }

  private passwordMatchValidator(): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const newPassword = formGroup.get('newPassword')?.value;
      const confirmPassword = formGroup.get('confirmNewPassword')?.value;
      return newPassword && confirmPassword && newPassword !== confirmPassword ? { passwordsMismatch: true } : null;
    };
  }
  
  saveChangePassForm() {
    if (this.changePasswordForm.invalid) {
      return;
    }
    const requestBody: any = {
      "currentPassword": this.changePasswordForm.value.currentPassword || null,
      "newPassword": this.changePasswordForm.value.newPassword || null,
      "confirmNewPassword": this.changePasswordForm.value.confirmNewPassword || null,
    };

    if (this.changePasswordForm.valid) {
      this.changePasswordService.changePassword(requestBody).subscribe((res:any) => {
          this.changePasswordForm.reset();
        }
      );
    }
  }
 
  toggleCurrentPasswordVisibility() {
    this.currentPasswordVisible = !this.currentPasswordVisible;
  }

  toggleNewPasswordVisibility() {
    this.newPasswordVisible = !this.newPasswordVisible;
  }

  toggleConfirmPasswordVisibility() {
    this.confirmPasswordVisible = !this.confirmPasswordVisible;
  }

}

