import { routeGuardMapping } from "../constants/route-guard-mapper";

export const findChildRouteMapping = (parentRoute: string) => {
    const accessMapping: any = {
        'UI.Dashboard': [
            'UI.Dashboard.CampaignDataToday',
            'UI.Dashboard.BannerDataToday',
            'UI.Dashboard.CampaignDataYesterday',
            'UI.Dashboard.BannerDataYesterday',
            'UI.Dashboard.ActiveCampaignReport',
            'UI.Dashboard.BannerQC',
            'UI.Dashboard.ActiveBanners'
        ],
        'UI.Settings': [
            'UI.Settings.Roles',
            'UI.Settings.Policy',
            'UI.Settings.Users',
            'UI.Settings.Password',
            'UI.Settings.Tenant',
        ],
        'UI.Delivery': [
            'UI.Delivery.Orders',
            'UI.Delivery.Campaigns',
            'UI.Delivery.Placements',
            'UI.Delivery.Banners',
        ],
        'UI.Accounts': [
            'UI.Accounts.Advertiser-Agency',
            'UI.Accounts.BrandsBus',
            'UI.Accounts.Contacts',
            'UI.Accounts.Legal-Entities',
        ]
    };
    const matchingAccess = accessMapping[parentRoute].find((access: string) => 
        sessionStorage.getItem('permission')?.split(',')?.find((role: string) => access === role)
    );
    let matchingRoute: any = {};
    Object.keys(routeGuardMapping).forEach((k: any) => {
        if (matchingAccess === routeGuardMapping[k]) {
            matchingRoute = k;
        }
    });

    return matchingRoute;
}