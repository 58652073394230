<div class="card sub-container">
  <div *ngFor="let banner of banners">
    <p style="font-weight: 500;">{{ banner.bannerMain }} - {{ banner.name }}</p>
    <div *ngIf="bannerResponses[banner.channelId + '-' + banner.bankPlacementId]">
      <div class="img-container">
        <div *ngFor="let response of bannerResponses[banner.channelId + '-' + banner.bankPlacementId]">
          <div *ngFor="let image of response.body[0].images">
            <img [src]="image" alt="Banner Image" style="width: 200px; height: 100px;" />
            <p style="font-size: 9px;">{{ response.metaData?.campaignName }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="activebanner-container">
  <mat-card>
    <div class="dashboard-head">
      <div fxLayout="row">
        <h2>Active banners</h2>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" class="title-subtitle">
        <div fxLayoutAlign="space-between center" fxLayoutGap="50">
          <h2 class="mt-3">Ad unit</h2>
          <mat-form-field appearance="outline" class="mt-4 width-for-dropdown">
            <mat-select placeholder="Select adunit">
              <mat-option *ngFor="let item of channelList" [value]="item?.tenantName">{{ item.tenantName}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-card>
  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="10px" class="banner-table-qc">
    <mat-card fxFlex="100" class="responsive-card">
      <div class="card sub-container">
        <div *ngFor="let banner of banners">
          <p style="font-weight: 500;">{{ banner.bannerMain }} - {{ banner.name }}</p>
          <div *ngIf="bannerResponses[banner.channelId + '-' + banner.bankPlacementId]">
            <div class="img-container">
              <div *ngFor="let response of bannerResponses[banner.channelId + '-' + banner.bankPlacementId]">
                <div *ngFor="let image of response.body[0].images">
                  <img [src]="image" alt="Banner Image" style="width: 200px; height: 100px;" />
                  <p style="font-size: 9px;">{{ response.metaData?.campaignName }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div> -->