import { Injectable } from '@angular/core';
import { ApplicationApiService } from './application-api.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardControlService {
  isDelete: boolean;
  isNewEntry: boolean;
  isStageSave:boolean;
  showPlacementSaveInAdunit:boolean;

  public _isComingFromSidebar: boolean;
  public _isPlacementFromCampaign: boolean;
  public _isCampaignComingFromOrder:boolean;
  private showDetails = false;

  constructor( private applicationApiService:ApplicationApiService) { }
  get isComingFromSidebar() {
    return this._isComingFromSidebar;
  }
  set isComingFromSidebar(data) {
    this._isComingFromSidebar = data;
  }

  get isPlacementFromCampaign() {
    return this._isPlacementFromCampaign;
  }
  set isPlacementFromCampaign(data: boolean) {
    this._isPlacementFromCampaign = data;
  }

  get isCampaignComingFromOrder() {
    return this._isCampaignComingFromOrder;
  }
  set isCampaignComingFromOrder(data: boolean) {
    this._isCampaignComingFromOrder = data;
  }


  get isDetailsVisible(): boolean {
    return this.showDetails;
  }

  showDetailsColumn() {
    this.showDetails = true;
  }

  hideDetailsColumn() {
    this.showDetails = false;
  }
  getDashboardList(dateFrom:any, dateTo:any) {

    const queryParams = {
      dateFrom:dateFrom,
      dateTo:dateTo,
      includeBannerData:true

    }
    return this.applicationApiService.getReportData("GetCTRReport", queryParams);
  }
  getDashboardListCampaign(dateFrom:any, dateTo:any) {

    const queryParams = {
      dateFrom:dateFrom,
      dateTo:dateTo,
      includeBannerData:false

    }
    return this.applicationApiService.getReportData("GetCTRReport", queryParams);
  }



}
