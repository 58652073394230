export const routeGuardMapping : any= {
    "/accounts": "UI.Accounts",
    "/accounts/advertiser-agency":"UI.Accounts.Advertiser-Agency",
    "/accounts/details":"Account.GetAllAccountById",
    "/accounts/create-account":"Account.CreateAccount",
    "/accounts/brands": "Brand.GetAllBrand",
    "/accounts/contact": "UI.Accounts.Contacts",
    "/accounts/legalEntities": "UI.Accounts.Legal-Entities",
    "/accounts/brands/details": "Brand.GetAllBrandByAccountId",
    "/accounts/contact/details/": "ClientContact.GetClientContactByAccountId",
    "/accounts/legalEntities/details": "LegalEntity.GetLegalEntityByAccountId",

    "/delivery": "UI.Delivery",
    "/delivery/order": "UI.Delivery.Orders",
    "/delivery/order/details":"Order.GetAllOrderById",
    "/delivery/campaigns": "UI.Delivery.Campaigns",
    "/delivery/campaign/create-campaign/": "Campaign.CreateCampaign",
    "/delivery/campaigns/details": "Campaign.GetAllCampaignById",
    "/delivery/placement": "UI.Delivery.Placements",
    "/delivery/placement/details": "Placement.GetAllPlacementById",
    // "/delivery/placement/create-placement":"Placement.AddPlacement",
    "/delivery/placement/create-placement":"Placement.CreatePlacement",

    "/delivery/banners": "UI.Delivery.Banners",
    "/delivery/banners/create-banners":"Banner.CreateBanner",
    "/delivery/banners/details":"Banner.GetBannersById",
    "/delivery/create-order": "Order.CreateOrder",

    "/delivery/banner-approval": "UI.BannerApprovals",

    "/ad-units": "UI.Adunits",
    "/ad-units/create-ad-units": "AdUnit.CreateAdUnit",
    "/ad-units/details": "AdUnit.GetAllAdUnitById",
    "/GetActiveCampaigns":"AdReports.getActiveCampaignReport",
    "/pricing": "UI.Pricing",
    "/pricing/create-pricing": "CampaignPricing.CreateCampaignPricing",
    "/pricing/details":"CampaignPricing.GetAllCampaignPricingById",
    "/password": "UI.Settings.Password",
    "/setting/policy": "UI.Settings.Policy",
    "/setting/roles": "UI.Settings.Roles",
    "/setting/users": "UI.Settings.Users",
    "/setting/tenant": "UI.Settings.Tenant",
    "/setting/roles/roles-create": "Role.CreateRole",
    "/setting/roles/details": "Role.GetAllRole",
    "/setting/policy/policy-create": "Policy.CreatePolicy",
    "/setting/policy/details": "Policy.GetAllPolicies",
    "/setting/users/users-create": "User.CreateUser",
    "/setting/users/details": "User.CreateUser",
    "/setting/tenant/tenant-create": "Tenant.CreateTenant",
    "/setting/tenant/details": "Tenant.CreateTenant",

    "/custom-audience": "UI.CustomAudience",
    "/custom-audience/create-custom-audience": "Custom.CreateCustomAudience",
    
    "/campaign-today": "UI.Dashboard.CampaignDataToday",
    "/banner-today": "UI.Dashboard.BannerDataToday",
    "/campaign-yesterday": "UI.Dashboard.CampaignDataYesterday",
    "/banner-yesterday": "UI.Dashboard.BannerDataYesterday",
    "/active-campaign": "UI.Dashboard.ActiveCampaignReport",
    "/banner-qc": "UI.Dashboard.BannerQC",
    "/active-banners": "UI.Dashboard.ActiveBanners",

    

};

export const isRouteWithoutPermission = (url: string) => {
    const routes = [
        "campaign-report",
        "campaign-data"
    ];
    return !!routes.find(route => url.includes(route));
}