import { EventEmitter, Injectable } from '@angular/core';
import { ApplicationApiService } from './application-api.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  public _userFormData:any;
  isUserLoggedInChanged = new EventEmitter<string>();

  constructor(private applicationApiService:ApplicationApiService) { }

  set isUserLoggedIn(value: string) {
    this.isUserLoggedInChanged.emit(value);
  }

  getRoleFormData() {
    return this._userFormData;
  }
  setRoleFormData(data: any) {
    this._userFormData = data;
  }

  getUserTableData() {
    const queryParamsDropdown = {
      "pageNumber":1,
      "rows":2000,
      "sortingColumn":"none",
      "sortType":"ASC"
    }
    return this.applicationApiService.getData("user", queryParamsDropdown);
  }

  getMultipleRolesMappingByUserId(id: string) {
    return this.applicationApiService.getData(`User/GetRoles/${id}`, "");
  } 

  saveUser(body:any){
    return this.applicationApiService.postData("user",body);
  }
  saveRoleInsideUsers(body:any){
    return this.applicationApiService.postData("User/AddRoles",body);
  }
  updateUser(body:any){
    return this.applicationApiService.putData("user",body);
  }

saveUserDetail(roleId: any, userId: any) {
  const url = `Role/User?roleId=${roleId}&userId=${userId}`;
  const body = { roleId, userId };

  return this.applicationApiService.postData(url, body);
}

saveUserDetails(body:any){
  return this.applicationApiService.postData("Role/Users",body)
}

deleteMultipleRoleFromUser( roleId: number,userId: number,){
  const body = {
    userId: userId,
    roles: [roleId],
  };
  return this.applicationApiService.deleteDataWithParams("User/DeleteRoles", body);
}
  isAdminUser(userName: any) {
    const body = JSON.stringify(userName);
    return this.applicationApiService.postData('authenticate', body);
  }
}
