<div class="error-container">
    <div fxLayout="column" fxLayoutAlign="space-between center">
        <div>
            <h2>{{ data?.title }}</h2>
        </div>
        <div>
            <h3>
                {{ data?.errorMessage }}
            </h3>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="flex-end center">
        <button mat-raised-button color="primary" (click)="close()">Exit</button>
        <!-- <button mat-raised-button color="primary" (click)="retry()">Retry</button> -->
    </div>
</div>