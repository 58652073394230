import { Injectable } from '@angular/core';
import { ApplicationApiService } from './application-api.service';

@Injectable({
  providedIn: 'root'
})
export class PlacementService {
  submitForm(formValue: any) {
    throw new Error('Method not implemented.');
  }
  getPlacementData() {
    throw new Error('Method not implemented.');
  }
public _placementFormData:any;
public _targetingFormData:any;
public setBannerFromPlacement: any;

getPlacementFormData(){
return this._placementFormData;
}
setPlacementFormData(data:any){
this._placementFormData = data;
}

getTargetFormData(){
  return this._targetingFormData;
  }
  setTargetFormData(data:any){
  this._targetingFormData = data;
  }
  constructor( private applicationApiService:ApplicationApiService) { }

  getPlacementTableList(){
    const qureyParams = {
      "pageNumber":1,
      "rows":1000,
      "sortingColumn":"none",
      "sortType":"ASC"
    }
    return this.applicationApiService.getData("Placement",qureyParams);
  }

  getPlacementById(id: string) {
    return this.applicationApiService.getData(`Placement/${id}`, "");
  } 
  savePlacement(body:any){
    return this.applicationApiService.postData("Placement",body);
  }
  
  updatePlacement(body:any){
    return this.applicationApiService.putData("Placement",body);
  }
  getPlacementByCampaignId(id:any){
    return this.applicationApiService.getData("Placement/Campaign/"+id,"");
  }
  deletePlacementInCampaign(id:number){
    return this.applicationApiService.deleteData("Placement/" + id)
  }

 
  getPlacementBannerPacingDropdown(){
    return this.applicationApiService.getData("code/codetypename/Placement%20Banner%20Pacing","")
  }
  getTargettingTypeDropdown(){
    return this.applicationApiService.getData("code/codetypename/Placement%20Targeting%20Type","")
  }
  getPlacementFcap(){
    return this.applicationApiService.getData("Placement/FrequencyCap/" + (this._placementFormData?.Id || this._placementFormData['Placement Id']), "" )
  }
  savePlacementFcap(body:any){
    return this.applicationApiService.putData("Placement/FrequencyCap",body);
  }
  savePlacementDayTimeParting(body:any){
    return this.applicationApiService.putData("Placement/DaytimeParting/",body)
  }
  getPlacementDayTimeParting(){
    return this.applicationApiService.getData("Placement/DaytimeParting/" + this._placementFormData?.Id,"")
  }
  getPlacementTargetingGroup(){
    return this.applicationApiService.getData("TargetingGroup/Placement/" + (this._placementFormData?.Id|| this._placementFormData['Placement Id']), "" )
  }
  // getTargettingGroup(){
  //   const qureyParams = {
  //     "pageNumber":1,
  //     "rows":100,
  //     "sortingColumn":"none",
  //     "sortType":"ASC"
  //   }
  //   return this.applicationApiService.getData("TargetingGroup",qureyParams);
  // }
  saveTargettingGroup(body:any){
    return this.applicationApiService.postData("TargetingGroup",body);
  }
  updateTargettingGroup(body:any){
    return this.applicationApiService.putData("TargetingGroup",body);
  }
  deleteTargettingGroup(id:number){
    return this.applicationApiService.deleteData("TargetingGroup/" + id)
  }
  // deleteBannerFromPlacement(bannerId:number, placementId:number){
  //     const qureyParams = {
  //     "bannerId":bannerId,
  //     "placementId":placementId
  //   }
  //   return this.applicationApiService.deleteDataWithParams("Banner/Placement",qureyParams);
  // }

  deleteBannerFromPlacement(bannerId: number, placementId: number) {
    const url = `Banner/Placement/${bannerId}/${placementId}`;
    return this.applicationApiService.deleteData(url);
  }

  

  getCodeTypeName(codeTypeName: string, codeDescriptionPattern: string = '') {
    let url = `Code/CodeTypeName/${encodeURIComponent(codeTypeName)}`;
    if (codeDescriptionPattern) {
        url += `?codeDescriptionPattern=${encodeURIComponent(codeDescriptionPattern)}`;
    }
    return this.applicationApiService.getData(url,'');
}
}
