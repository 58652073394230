<div class="table-container">
  <div [ngClass]="wrap? 'mat-elevation-z8 wrap' : 'mat-elevation-z8'">
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
        <ng-container>
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="custom-header">{{ column }}</th>
          <td mat-cell *matCellDef="let item; let rowIndex = index;">
            <div class="progress" *ngIf="progressBar && column === 'Consumption'">
              <div class="progress-bar" role="progressbar" style="width: 50%" aria-valuenow="25" aria-valuemin="0"
                aria-valuemax="100"></div>
            </div>
            <mat-slide-toggle [color]="isDisabled ? 'warn' : 'primary'" [ngStyle]="isDisabled ? {'opacity': 0.6} : {}"
              [disabled]="isDisabled" [checked]="item.Status" color="primary" *ngIf="slider && column === 'Status'"
              label=""></mat-slide-toggle>
            <button (click)="rowClicked('details', item)" mat-icon-button color="primary" *ngIf="column === 'Details'">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
            <mat-checkbox *ngIf="checkboxTable && column !== 'Action'" color="primary" [checked]="item[column]"
              [disabled]="true"></mat-checkbox>
            <div *ngIf="column === 'Action'">
              <mat-icon color="primary" *ngIf="showEditIcon" (click)="updateCampaign(item, rowIndex)"
                (click)="updateOrder(item, rowIndex)" matTooltip="edit">edit</mat-icon>
              <mat-icon class="ms-1" *ngIf="showDeleteIcon" color="warn" (click)="deleteOrder(item, rowIndex)"
                matTooltip="delete">delete</mat-icon>
              <mat-icon color="warn" *ngIf="showRemoveIcon" (click)="removeBanner(item, rowIndex)"
                matTooltip="remove">cancel</mat-icon>

              <button *ngIf="hideAction" mat-raised-button color="accent" class="me-2"
                (click)="removeUserFromTenant(item, rowIndex)">Remove</button>
            </div>
            <div *ngIf="column === deeplinkImgColumn" class="deeplink-box">
              <img [src]="item[column]" (click)="redirectToItem(item)" alt="Image Preview">
            </div>


            <ng-container *ngIf="column === 'CustomId'" class="deeplink-box">
              {{item.customId}}
            </ng-container>
            <ng-container *ngIf="column === 'File Name'" class="deeplink-box">
              <div (click)="downloadFile(item.fileName)"
                style="cursor: pointer; color: blue; text-decoration: underline; width: auto; height: auto; border: none;"
                class="deeplink-box">
                {{item.fileName}}
              </div>
            </ng-container>
            <ng-container *ngIf="column === 'Process Status'" class="deeplink-box">
              {{item.process}}
            </ng-container>
            <ng-container *ngIf="column === 'Status'" class="deeplink-box">
              {{item.status}}
            </ng-container>
            <ng-container *ngIf="column === 'Remove Segment'" class="deeplink-box center">
              <div>
                <mat-checkbox [disabled]="item.status === 'Processing'" class="example-margin center" color="primary"></mat-checkbox></div>
            </ng-container>
            <ng-container *ngIf="column === 'Process'" class="deeplink-box center">
              <button color="primary" mat-raised-button (click)="uploadProcess(item)" class="me-2 center"
                [disabled]="item.status === 'Processing'">Process</button>
            </ng-container>
            
            <ng-container *ngIf="column === 'Delete'" class="deeplink-box">
              <button mat-raised-button color="accent" class="me-2" (click)="removeSelectedCsvFiles(item)"
              [disabled]="item.status === 'Processing'">Remove</button>
            </ng-container>

            <tr *ngIf="column === ' ' && item.status">
              <td>
                <div class="status-circle green"></div>
              </td>
            </tr>

            <ng-container *ngIf="!checkboxTable">
              <ng-container *ngIf="isImageUrl(item[column])">
                <img [src]="item[column]" (click)="displayImg(item[column])" width="50">
              </ng-container>
              <ng-container *ngIf="!isImageUrl(item[column]) && column !== deeplinkImgColumn">
                {{ item[column]?.['codeName'] || item[column] }}
              </ng-container>
            </ng-container>




          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator *ngIf="hidePagination" [pageSizeOptions]="pageSizes" aria-label="Select page of users">
    </mat-paginator>
  </div>
</div>