<div class="bannerqc-container">
  <mat-card>
    <div class="dashboard-head">
      <div fxLayout="row">
        <h2>Banners QC</h2>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center" class="title-subtitle">
        <div fxLayoutAlign="space-between center" fxLayoutGap="20">
          <h2 class="mt-3">Channel</h2>
          <mat-form-field appearance="outline" class="mt-4 width-for-dropdown">
            <mat-select placeholder="Select channel" [(ngModel)]="channelFilter">
              <mat-option *ngFor="let item of channelList" [value]="item?.channel">{{ item.channel}}</mat-option>
            </mat-select>
          </mat-form-field>

        </div>
        <div fxLayoutAlign="space-between center" fxLayoutGap="20">
          <h2 class="mt-3 ms-5">Ad Format</h2>
          <mat-form-field appearance="outline" class="mt-4 width-for-dropdown">
            <mat-select placeholder="Select Ad Format" [(ngModel)]="adFormatFilter">
              <mat-option *ngFor="let item of adFormatList" [value]="item?.key">{{ item.codeName}}</mat-option>
            </mat-select>
          </mat-form-field>

          <button mat-raised-button color="primary" (click)="applyFilterForBoth()">Go<mat-icon>play_arrow</mat-icon></button>
          <!-- <button mat-raised-button color="primary" (click)="applyFilterForAdFormat()">Go<mat-icon>play_arrow</mat-icon></button>
          <button mat-raised-button color="primary" (click)="applyFilterForChannel()">Go<mat-icon>play_arrow</mat-icon></button> -->
        </div>
      </div>
    </div>
  </mat-card>
  <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="10px" class="banner-table-qc">
    <mat-card fxFlex="50" class="responsive-card">
      <div class="table-scroll-pricing">
        <app-table class="special-table" [displayedColumns]="bannerdisplayedInactiveColumns"
          [deeplinkImgColumn]="'Banners'" (redirectTo)="redirectToOrder($event)"
          [dataSource]="bannerInactivedataSource" [slider]="true" [hidePagination]="true" [pageSizes]="[4,10, 20, 100]">
        </app-table>
      </div>
    </mat-card>

    <mat-card fxFlex="50" class="responsive-card">
      <div class="table-scroll-pricing">
        <app-table class="special-table" [displayedColumns]="bannerdisplayedActiveColumns"
          [deeplinkImgColumn]="'Live Active Banners'" (redirectTo)="redirectToOrder($event)"
          [dataSource]="bannerActivedataSource" [slider]="true" [hidePagination]="true" [pageSizes]="[4,10, 20, 100]">
        </app-table>
      </div>
    </mat-card>
  </div>
</div>