import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RoutesMenu } from 'src/app/utilities/menu';
import { MatDialog } from '@angular/material/dialog';
import { CustomAudienceService } from 'src/app/services/custom-audience.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-create-custom-audience',
  templateUrl: './create-custom-audience.component.html',
  styleUrls: ['./create-custom-audience.component.scss']
})
export class CreateCustomAudienceComponent implements OnInit {
  customAudienceForm!: FormGroup<any>;
  showEditOption: boolean = true;
  file: File | null = null;
  uploadedFile: any;
  showEdit:boolean=false;

  constructor(private router: Router, private fb: FormBuilder,
    public dialog: MatDialog, private route: ActivatedRoute,
    public customAudience: CustomAudienceService, private sanitizer: DomSanitizer
  ) {

  }
  ngOnInit() {
    this.showEditOption = this.customAudience._roleFormData;
    const formData = this.customAudience._roleFormData || null;
    this.customAudienceForm = this.fb.group({
      SegmentName: [formData?.["segment_name"] || null, Validators.required],
      SegmentDescription: [formData?.["segment_description"] || null,Validators.required],
      file_path: [formData?.data?.["file_path"]],
      id: [formData?.["id"] || null],
    });
    if (this.showEditOption) {
      this.customAudienceForm.disable();
    }
  }
  getFileName() {
    const filePath = this.customAudienceForm.get('file_path')?.value;
    return filePath ? filePath.split('/').pop() : null;
  }

  getSafeUrl() {
    const filePath = this.customAudienceForm.get('file_path')?.value;
    return filePath ? this.sanitizer.bypassSecurityTrustUrl(filePath) : '';
  }
  saveRoleForm() {
    if (this.customAudienceForm.invalid) {
      return;
    }
    const requestBody: any = {
      "id": this.customAudienceForm.value.id,
      "SegmentName": this.customAudienceForm.value.SegmentName,
      "SegmentDescription": this.customAudienceForm.value.SegmentDescription || null,
      "file": this.uploadedFile,
    };
    const { id, SegmentName, SegmentDescription, file } = requestBody;
    if (this.customAudienceForm.valid) {
      if (this.customAudience._roleFormData) {
      }
      (this.customAudience._roleFormData ? 
        this.customAudience.UpdateCustomAudience(id, SegmentName, SegmentDescription, file) :
        this.customAudience.createCustomAudience(SegmentName, SegmentDescription, file))
        .subscribe((res: any) => {
          this.router.navigate([RoutesMenu.customAudience]);
        })
    }

  }
  backToRolesPage() {
    this.router.navigate([RoutesMenu.customAudience])
  }
  OnEditEnable() {
    this.customAudienceForm.enable();
    this.customAudienceForm.get('file_path')?.enable(); // Explicitly enable the file path input

  }
  delete() {
    const isConfirmed = window.confirm("Are you sure you want to delete this data?");
    if (isConfirmed) {
      this.customAudience.deleteCustomAudience(this.customAudience._roleFormData.id || this.customAudience._roleFormData.id).subscribe(response => {
        this.router.navigate([RoutesMenu.customAudience]);

      });
    } else {
      console.log("Deletion canceled by the user.");
    }
  }

  goToUserBox() {
    this.router.navigate([RoutesMenu.customAudience]);
  }

  onFileChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadedFile = file;
    }
  }
  downloadFile() {
    const filePath = this.customAudienceForm.get('file_path')?.value;
    if (filePath) {
      const link = document.createElement('a');
      link.href = filePath;
      link.download = '';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('No file path available for download');
    }
  }

}
