import { Component, OnInit } from '@angular/core';
import { BannerService } from 'src/app/services/banner.service';

@Component({
  selector: 'app-active-banners',
  templateUrl: './active-banners.component.html',
  styleUrls: ['./active-banners.component.scss']
})
export class ActiveBannersComponent implements OnInit {
  channelCanaraOne: any

  banners: any[] = [];
  bannerResponses: { [key: string]: any[] } = {}
  constructor(private bannerService: BannerService) {
    this.banners = bannerService.getBanners()
  }
  ngOnInit(): void {
    this.loadBannerData();
  }



  loadBannerData() {
    this.banners.forEach(banner => {
      this.bannerService.AdRequestData(banner.channelId, banner.bankPlacementId).subscribe(
        res => {
          const key = `${banner.channelId}-${banner.bankPlacementId}`;
          this.bannerResponses[key] = res;
        },
        err => {
          console.error('Error posting data for', banner, err);
        }
      );
    });
  }
}
