export const RoutesMenu = {
    login: 'login',

    otp: 'otp',

    dashboard: 'dashboard',

    accounts: 'accounts',
    accountsAdvertiserAgency: 'accounts/advertiser-agency',
    accountsCreate: 'accounts/create-account',
    accountsDetails: 'accounts/details',

    accountsBrands: 'accounts/brands',
    accountsBrandsCreate: 'accounts/brands/details',

    accountsContact: 'accounts/contact',
    accountsContactCreate: 'accounts/contact/details',

    accountsLegalEntities: 'accounts/legalEntities',
    accountsLegalEntitiesCreate: 'accounts/legalEntities/details',

    deliveryOrder: 'delivery/order',
    deliveryCreate: 'delivery/create-order',
    deliveryDetails: 'delivery/order/details',

    deliveryCampaign: 'delivery/campaigns',
    deliverCampaignDetail: 'delivery/campaigns/details',
    deliveryCampaignCreate: 'delivery/campaign/create-campaign',

    deliveryCampaignPlacementDetail: 'delivery/campaign/placement/placement-details',
    deliveryPlacementsDetail: 'delivery/placement/details',
    deliveryPlacements: 'delivery/placement',
    deliveryPlacementsCreate: 'delivery/placement/create-placement',

    deliveryBanners: 'delivery/banners',
    deliveryBannersCreate: 'delivery/banners/create-banners',
    deliveryBannersDetails: 'delivery/banners/details',

    deliveryBannersApprovals: 'delivery/banner-approval',

    adUnits: 'ad-units',
    adUnitsCreate: 'ad-units/create-ad-units',
    adUnitsDetails: 'ad-units/details',

    pricing: 'pricing',
    pricingCreate: 'pricing/create-pricing',
    pricingDetail: 'pricing/details',

    settings: 'settings',
    password: 'password',

    policy: 'setting/policy',
    policyCreate: 'setting/policy/policy-create',
    policyDetails: 'setting/policy/details',
    roles: 'setting/roles',
    rolesCreate: 'setting/roles/roles-create',
    rolesDetails: 'setting/roles/details',
    users: 'setting/users',
    usersCreate: 'setting/users/users-create',
    usersDetails: 'setting/users/details',
    tenant: 'setting/tenant',
    tenantCreate: 'setting/tenant/tenant-create',
    tenantDetails: 'setting/tenant/details',

    campaignToday: 'campaign-today',
    campaignYesterday: 'campaign-yesterday',
    bannerToday: 'banner-today',
    bannerYesterday: 'banner-yesterday',
    activeCampaignReport: 'active-campaign',
    campaigData:'campaign-data',
    activeBanners: 'active-banners',
    errors: 'errors',

    bannersQC: 'banners-QC',

    customAudience: 'custom-audience',
    CreatecustomAudience: 'custom-audience/create-custom-audience',
}
